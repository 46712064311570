import "./FilterItems.css";
import {
    Box,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Checkbox,
    CheckboxGroup,
} from "@chakra-ui/react";
import { BedLengthEnum, getBedLengthEnum } from "../Enums/BedLengthEnum";


const BedLength = (props: any) => {

    const { setBedLengthFilter } = props;
    function onChange(e: any) {
        setBedLengthFilter(e.target.checked)
    }

    return (
        <AccordionItem className="filter-div-acordion-item">
            <h2>
                <AccordionButton className="acordion-button">
                    <Box as="span" flex='1' textAlign='left' height={10}>
                    Bed Length
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} className="acordion-panel">
                <CheckboxGroup>
                    {
                        getBedLengthEnum().map((key, index) => (
                            <div key={index} className="checkbox-color-div">
                                <Checkbox onChange={(e) => onChange(e)} colorScheme='red' size='lg' className="color-checkbox">
                                    {BedLengthEnum[key]}
                                </Checkbox>
                            </div>
                        ))
                    }
                </CheckboxGroup>
            </AccordionPanel>
        </AccordionItem>
    );
};

export default BedLength;
