import "./FilterItems.css";
import {
    Box,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Checkbox,
} from "@chakra-ui/react";

const CPOFilter = (props: any) => {
    const { setCPOFilter } = props;
    function onChange(e: any) {
        setCPOFilter(e.target.checked)
    }
    return (
        <AccordionItem className="filter-div-acordion-item">
            <h2>
                <AccordionButton className="acordion-button">
                    <Box as="span" flex='1' textAlign='left' height={10}>
                        CPO
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} className="acordion-panel">
                <Checkbox onChange={(e)=>onChange(e)} colorScheme='red' size='lg'>Certified Pre-Owned</Checkbox>
            </AccordionPanel>
        </AccordionItem>
    );
};

export default CPOFilter;
