import "./ImageDetail.css";

import { Swiper, useSwiper, SwiperProps, SwiperSlide } from "swiper/react";
import React, { useRef, useState, Fragment, useEffect, useLayoutEffect } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs } from "swiper";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Swiper as Swipers, SwiperEvents } from "swiper/types";

interface CarImageDetail {
  modalState: boolean;
  imageDetail: any
}
interface Test {
  modalState: boolean;
  imageDetail: any
}
let count = 0;

const ImageDetail = (props: CarImageDetail) => {
  const [swiper, setSwiper] = useState<Swipers>();

  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateSize);
    updateSize();

  }, []);//Bu Fonskiyon Responsive Tetiklemek için
  const [test, settest] = useState<Swipers>();
  const [screenWidth, setScreenWidth] = useState(window.screen.availWidth);
  const { modalState, imageDetail } = props;

  useEffect(() => {

    if (count % 2 === 1) {
      settest(swiper)
    }
    if (swiper) {
      count = count + 1;
    }
  }, [swiper]);

  return (<Fragment>
    {screenWidth > 800 ? <Modal size={"6xl"}  isOpen={modalState} onClose={() => imageDetail(false)} >
      <ModalOverlay  />

      <ModalContent borderRadius={15}>
    
          <Fragment>
            <Swiper
style={{borderTopLeftRadius:15,borderTopRightRadius:15}}
              loop={true}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: test }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6}} src="../carImageDetail/3.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/2.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/1.jpg" />
              </SwiperSlide>
           
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/5.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/6.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/7.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/8.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/9.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/10.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/11.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/12.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/13.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/14.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/15.jpg" />
              </SwiperSlide>
              <SwiperSlide>
                <img style={{ height: window.screen.availHeight * 0.6 }} src="../carImageDetail/16.jpg" />
              </SwiperSlide>
            </Swiper>

            <div className="container" style={{ width: "100%" }}>
              <div className="row" style={{ width: "100%" }}>
                <div style={{ width: "5%" }}>
                  <div className="buttonPrev" onClick={() => swiper?.slidePrev()}>{'<'}</div>
                </div>
                <div style={{ width: "90%" }}>
                  <Swiper
                    style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}
                    onSwiper={setSwiper}

                    loop={false}
                    spaceBetween={10}
                    slidesPerView={8}
                    freeMode={true}
                    watchSlidesProgress={false}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                  >

                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/3.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/2.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/1.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/4.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/5.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/6.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/7.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/8.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/9.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/10.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/11.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/12.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/13.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/14.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/15.jpg" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img style={{ cursor: "pointer", borderRadius: "8px" }} src="../carImageDetail/16.jpg" />
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div style={{ width: "5%" }}>
                  <div className="buttonNext" onClick={() => swiper?.slideNext()}>{'>'}</div>
                </div>
              </div>
            </div>
            <div>

            </div>
          </Fragment>
        <ModalCloseButton style={{ zIndex: 1000 }} />

      </ModalContent>
    </Modal>
      : <Modal size={"xs"} isOpen={modalState} onClose={() => imageDetail(false)} >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 , paddingBottom: 0 }}>
          <img src="../carImageDetail/1.jpg" />
          <img src="../carImageDetail/2.jpg" />
          <img src="../carImageDetail/3.jpg" />
          <img src="../carImageDetail/4.jpg" />
          <img src="../carImageDetail/5.jpg" />
          <img src="../carImageDetail/6.jpg" />
          <img src="../carImageDetail/7.jpg" />
          <img src="../carImageDetail/8.jpg" />
          </ModalHeader>
          <ModalCloseButton color={"white"} bgColor={"#1A1A1A"}  style={{ zIndex: 1000 }} />
        </ModalContent>
      </Modal>
    }</Fragment>
  );
};


export default ImageDetail;

