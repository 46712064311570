import { useState, useEffect, Fragment } from "react";
import "./Filters.css";

import { makeList, ModelList } from "../../../Model/InıtData/initData";
import {
  Input, InputGroup, Select, InputLeftElement,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Box,
  FormErrorMessage,
  Accordion,
} from "@chakra-ui/react";
import Cookies from 'universal-cookie';
import { SortTypes } from "./Enums/SortingTypes";
// import CPOFilter from "../../FilterItems/CPOFilter";
import ONlineDealers from "./Items/OnlineDealers";
import ExteriorColor from "./Items/ExteriorColor";
import PopularFeatures from "./Items/PopularFeatures";
import Transmission from "./Items/Transmission";
import ConditionandHistory from "./Items/ConditionandHistory";
import DriveType from "./Items/DriveType";
import Engine from "./Items/Engine";
import InteriorColor from "./Items/InteriorColor";
import FuelType from "./Items/FuelType";
import BedLength from "./Items/BedLength";
import CabType from "./Items/CabType";
import RoofHeight from "./Items/RoofHeight";
import CarListModel from "../../../Model/CarListModel";
import CPOFilter from "./Items/CPOFilter";
import FilterModel from "../../../Model/FilterModel";
let favoriteCarId: number[];
interface FilterItem {
  FilterModel: FilterModel;
  carListTemp: CarListModel[];
  carList: CarListModel[];
  setCarLists: any;
  setCarListTemps: any;
  sort: any;
  filterClear: any;
  setLocationName: any;
  setKeyControl: any;
  setFilterClear: any;
  keyControl: any; switchBar: any; setSwitchBar: any; setFilterController: any; screenWidth: any; setPageNumber: any
}

const Filters = (props: FilterItem) => {

  const cookies = new Cookies();
  const { FilterModel, carList, setPageNumber, screenWidth, carListTemp, setFilterClear, setKeyControl, setCarLists, setCarListTemps, sort, filterClear, setLocationName, keyControl, switchBar, setSwitchBar, setFilterController } = props;
  const [locationModal, setLocationModal] = useState(false);
  


  const [filterCount, setFilterCount] = useState(false);
  const [isError, setIsError] = useState(true);
  const [isFilter, setIsFilter] = useState(false);//sayfada filtreli bi öğe varmı ?

  const [CPOFilterValue, setCPOFilter] = useState(false);
  const [ONlineDealersFilterValue, setONlineDealersFilter] = useState(false);
  const [ExteriorColorFilterValue, setExteriorColorFilter] = useState([]);
  const [PopularFeaturesFilter, setPopularFeaturesFilter] = useState([]);
  const [TransmissionFilter, setTransmissionFilter] = useState([]);
  const [ConditionandHistoryFilter, setConditionandHistoryFilter] = useState([]);
  const [DriveTypeFilter, setDriveTypeilter] = useState([]);
  const [EngineFilter, setEngineFilter] = useState([]);
  const [InteriorColorFilter, setInteriorColorFilter] = useState([]);
  const [FuelTypeFilter, setFuelTypeFilter] = useState([]);
  const [BedLengthFilter, setBedLengthFilter] = useState([]);
  const [CabTypeFilter, setCabTypeFilter] = useState([]);
  const [RoofHeightFilter, setRoofHeightFilter] = useState([]);
  const year = new Date().getFullYear() + 1;
  const years = Array.from(new Array(20), (val, index) => year - index);


  useEffect(() => {

    let dataTest = carList.slice();
    let filteredData = sorting(dataTest);
    setCarLists(filteredData);
  }, [sort]);//Sıralama  effect
  useEffect(() => {

    filterCar();
  }, [
    FilterModel

  ]);


  useEffect(() => {

    if (switchBar == 1) {
      if (isFilter) {
        filterCar(carListTemp);
      }
      else {
        setCarLists(carListTemp);
      }
    }
    else if (switchBar == 2) {
      if (isFilter) {
        filterCar(carListTemp.filter(c => c.miles == "0"));
      }
      else {
        setCarLists(carListTemp.filter(c => c.miles == "0"));
      }

      setCPOFilter(false);
      setONlineDealersFilter(false);
      setConditionandHistoryFilter([]);
    }
    if (switchBar === 3) {
      if (cookies.get('FavoriteList')) {
        if (isFilter) {
    
          favoriteCarId = cookies.get('FavoriteList')
          filterCar(carListTemp.filter(x => favoriteCarId.includes(x.id)));
        }
        else {
          favoriteCarId = cookies.get('FavoriteList')
          let data = carListTemp.filter(x => favoriteCarId.includes(x.id))

          setCarLists(data);

        }

      }
      else {
        setCarLists([]);
      }
    }
  }, [switchBar, keyControl]);

  useEffect(() => {
    if (filterCount) {
      setFilterController(true)
    }
  }, [carList]);

  function filterCar(dumyData?: CarListModel[]) {
    let dumyIsFilter = false;

    let filteredData = dumyData ? dumyData : switchBar == 2 ? carListTemp.filter(c => c.miles == "0") : switchBar == 3 ? carListTemp.filter(x => favoriteCarId?.includes(x.id)) : carListTemp;

    if (FilterModel.makeVal && FilterModel.makeVal !== "") {
      filteredData = filteredData.filter((cars) => cars.make === FilterModel.makeVal);
      if (FilterModel.modelVal && FilterModel.modelVal !== "") {
        filteredData = filteredData.filter((cars) => cars.model === FilterModel.modelVal);
      }
      dumyIsFilter = true;
    }
    if (FilterModel.Distance && FilterModel.Distance !== "" && FilterModel.Distance !== "All") {
      dumyIsFilter = true;
      filteredData = filteredData.filter((cars) => parseFloat(cars.distance.replace(" miles away", "")) <= parseFloat(FilterModel.Distance));
    }

    if (FilterModel.zipCodeSuccess && FilterModel.zipCodeSuccess !== "" && FilterModel.zipCodeSuccess !== "All") {
      dumyIsFilter = true;
      filteredData = filteredData.filter((cars) => cars.location.toUpperCase().includes(FilterModel.zipCodeSuccess.toUpperCase()));
    }
    if (FilterModel.minYearVal) {
      dumyIsFilter = true;
      filteredData = filteredData.filter((cars) => cars.year >= FilterModel.minYearVal);
    }
    if (FilterModel.maxYearVal) {
      dumyIsFilter = true;
      filteredData = filteredData.filter((cars) => cars.year <= FilterModel.maxYearVal);
    }
    if (FilterModel.minPrice) {
      dumyIsFilter = true;
      filteredData = filteredData.filter(
        (cars) => Number(cars.price.replace(",", "")) >= Number(FilterModel.minPrice.replace(",", ""))
      );
    }
    if (FilterModel.maxPrice) {
      dumyIsFilter = true;
      if (FilterModel.maxPrice !== "100,000+")
        filteredData = filteredData.filter(
          (cars) => Number(cars.price.replace(",", "")) <= Number(FilterModel.maxPrice.replace(",", ""))
        );
    }
    if (FilterModel.minMileageFilter) {
      dumyIsFilter = true;
      filteredData = filteredData.filter(
        (cars) =>
          Number(cars.miles.replace(",", "")) >= Number(FilterModel.minMileageFilter.replace(",", ""))

      );
    }
    if (FilterModel.maxMileageFilter) {
      dumyIsFilter = true;
      if (FilterModel.maxMileageFilter !== "200,000+")
        filteredData = filteredData.filter(
          (cars) =>
            Number(cars.miles.replace(",", "")) <= Number(FilterModel.maxMileageFilter.replace(",", ""))
        );
    }
    if (dumyIsFilter) {
      setPageNumber(1)
    } //filtreleme yaptıgında pagination 1 e cekmek icin
    setIsFilter(dumyIsFilter);
    filteredData = sorting(filteredData);
    setCarLists(filteredData);
  }





  function sorting(filteredData: CarListModel[]): CarListModel[] {
    let sortedData = filteredData;
    if (props.sort === SortTypes.Mileage_Low_to_High) {
      sortedData = filteredData.sort((a, b) =>
        Number(a.miles.replace(",", "")) > Number(b.miles.replace(",", ""))
          ? 1
          : -1
      );
    } else if (props.sort === SortTypes.Mileage_High_to_Low) {
      sortedData = filteredData.sort((a, b) =>
        Number(a.miles.replace(",", "")) < Number(b.miles.replace(",", ""))
          ? 1
          : -1
      );
    } else if (props.sort === SortTypes.Price_Low_to_High) {
      sortedData = filteredData.sort((a, b) =>
        Number(a.price.replace(",", "")) > Number(b.price.replace(",", ""))
          ? 1
          : -1
      );
    } else if (props.sort === SortTypes.Price_High_to_Low) {
      sortedData = filteredData.sort((a, b) =>
        Number(a.price.replace(",", "")) < Number(b.price.replace(",", ""))
          ? 1
          : -1
      );
    } else if (props.sort === SortTypes.Year_Low_to_High) {
      sortedData = filteredData.sort((a, b) => (Number(a.year) > Number(b.year) ? 1 : -1));
    } else if (props.sort === SortTypes.Year_High_to_Low) {
      sortedData = filteredData.sort((a, b) => (Number(a.year) < Number(b.year) ? 1 : -1));
    }
    // else if(props.sort==SortTypes.Best_Detail){
    // }
    else if (props.sort === SortTypes.Best_Macth) {
    }
    // else if(props.sort==SortTypes.Distance){

    // }
    // else if(props.sort==SortTypes.Newly_listed){
    // }
    return sortedData;
  } // Sıralama Algoritması


  const modelOnChange = (event: any) => {
    const value = event.target.value;
    FilterModel.setModelVal(value);
  };
  const minYearOnChange = (event: any) => {
    const value = event.target.value;
    
    FilterModel.setMinYearVal(value);
  };

  const saveLocation = () => {
    setLocationName(FilterModel.zipCode);
 
    FilterModel.setZipCodeSuccess(FilterModel.zipCode);
    FilterModel.setSaveLocation(!FilterModel.saveLocationProp);
    setLocationModal(false)
  };
  const maxYearOnChange = (event: any) => {
    const value = event.target.value;
    FilterModel.setMaxYearVal(value);
  };
  const changeZip = (event: any) => {
    event.target.value.length < 3 ? setIsError(true) : setIsError(false);
    FilterModel.setZipCode(event.target.value)
  };

  const minPriceOnKeyDown = (event: any) => {
    const value = event.target.value;
    FilterModel.setMinPriceVal(value);
  };
  const maxPriceOnKeyDown = (event: any) => {
    const value = event.target.value;
    FilterModel.setMaxPriceVal(value);
  };

  const minMileageChange = (event: any) => {
    const value = event.target.value;
    FilterModel.setMinMileageVal(value);
  };
  const maxMileageChange = (event: any) => {
    const value = event.target.value;
    FilterModel.setMaxMileageVal(value);
  };

  return (
    <Fragment>
      <Modal
        isOpen={locationModal}
        onClose={() => setLocationModal(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div style={{ marginTop: 25 }}>
              <FormControl isInvalid={isError}>
                <FormLabel>City or ZIP</FormLabel>
                <Input placeholder="City or ZIP" defaultValue={FilterModel.zipCode} onChange={(event) => changeZip(event)}

                />
                <FormErrorMessage>You must enter at least 3 characters</FormErrorMessage>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Distance</FormLabel>

                <Select
                  variant="filled"
                  defaultValue={FilterModel.Distance}
                  id="selectMake"
                  onChange={(event) => FilterModel.setDistance(event.target.value)}
                  style={{ backgroundColor: "white", borderColor: "#F7F1F1" }}
                >
                  <option value="5">5 miles</option>
                  <option value="10">10 miles</option>
                  <option value="25">25 miles</option>
                  <option value="50">50 miles</option>
                  <option value="75">75 miles</option>
                  <option value="100">100 miles</option>
                  <option value="150">150 miles</option>
                  <option value="200">200 miles</option>
                  <option value="250">250 miles</option>
                  <option value="500">500 miles</option>
                  <option value="All">All</option>
                </Select>
              </FormControl>
            </div>
            &nbsp;
          </ModalBody>
          <hr></hr>
          <ModalFooter>
            <Box w='100%'>
              <Button
                variant="ghost"
                className="mr-auto"
                onClick={() => setLocationModal(false)}
              >
                Cancel
              </Button>
            </Box>
            <Box w='20%' >
              <Button colorScheme="red" disabled={isError} onClick={() => saveLocation()}>Save</Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div className="filters-div">
        <div className="intro-div">
          <div className="switchbar-div">
            <div id="switchBarUsed" className={switchBar === 1 ? "div14" : "div16"} onClick={() => { setFilterCount(true); setFilterController(false); setSwitchBar(1) }}>
              <div className="best-deal-div">Used</div>
            </div>
            <div id="switchBarNew" className={switchBar === 2 ? "div14" : "div16"} onClick={() => { setFilterCount(true); setFilterController(false); setSwitchBar(2) }}>
              <div className="best-deal-div">New</div>
            </div>
            <div id="switchBarSaved" className={switchBar === 3 ? "div14" : "div16"} onClick={() => { setFilterCount(true); setFilterController(false); setSwitchBar(3) }}>
              <div className="best-deal-div">Saved</div>
            </div>
          </div>
        </div>
        <div className="basic-div">
          <div className="form-div location-div">
            <div className="contact-us-div">Location</div>
            <div className="link-div">
              <img className="iconlocation" alt="" src="../iconlocation.svg" />
              <div className="link-div1" onClick={() => setLocationModal(true)}>
                <div className="label-div">{FilterModel.zipCodeSuccess}</div>
                <img
                  className="iconexternal-link"
                  alt=""
                  src="../iconexternallink.svg"
                />
              </div>
            </div>
          </div>
          <div className="form-div">
            <div className="contact-us-div-new">Make</div>
            <Select
              key={1}
              data-testid="makeSelect"
              id="makeSelect"
              variant="unstyled"
              placeholder="All"
              className="select-div-new"
              value={FilterModel.makeVal}
              onChange={(e) => {
                FilterModel.setMakeVal(e.target.value);
                FilterModel.setModelVal("");
              }}
            >
              {Object.entries(makeList).map((opt, index) => (
                <option key={index} value={opt[1].Name}>{opt[1].Name}</option>
              ))}
            </Select>
          </div>
          <div className="form-div">
            <div className="contact-us-div-new">Model</div>
            <Select
              id="modelSelect"
              variant="unstyled"
              className="select-div-new"
              placeholder="All"
              value={FilterModel.modelVal}
              onChange={modelOnChange}
              disabled={FilterModel.makeVal === ""}
            >
              {Object.entries(
                ModelList.filter((f) =>
                  FilterModel.makeVal === "" ? true : f.MakeId === FilterModel.makeVal
                )
              ).map((opt, index) => (
                <option key={index} value={opt[1].Name}>{opt[1].Name}</option>
              ))}
            </Select>
          </div>
          <div className="form-div">
            <div className="contact-us-div">Years</div>
            <div className="form-div4">
              <div className="select-div4">
                <Select
                  id="yearsMinSelect"
                  data-testid="yearsMinSelect"
                  variant="unstyled"
                  className="select-div-new"
                  placeholder="Min"
                  value={FilterModel.minYearVal}
                  onChange={minYearOnChange}
                >
                  {years.map((y, index) => (
                    <option key={index} value={y}>{y}</option>
                  ))}
                </Select>
              </div>
              <div className="to-div">to </div>
              <div className="select-div4">
                <Select
                  id="yearsMaxSelect"
                  data-testid="yearsMaxSelect"
                  variant="unstyled"
                  className="select-div-new"
                  placeholder="Max"
                  value={FilterModel.maxYearVal}
                  onChange={maxYearOnChange}
                >
                  {years.map((y, index) => (
                    <option key={index} value={y}>{y}</option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-div">
          <div className="price-div">Price</div>
          <div className="content-div2">
            <div className="range-div">
              <InputGroup id="priceMinItemGroup">
                <InputLeftElement
                  id="priceMinLeftElement"
                  pointerEvents="none"
                  color="black.300"
                  fontSize="1.1em"
                  children="$"
                />
                <Input
                  id="priceMinInput"
                  data-testid="priceMinInput"
                  value={FilterModel.minPrice}

                  onKeyDown={(event: any) => {
                    const value = event.target.value;
                    FilterModel.setMinPriceVal(value);

                  }}
                  onChange={minPriceOnKeyDown}
                />
              </InputGroup>
              <div className="to-div">to</div>
              <InputGroup id="priceMaxItemGroup">
                <InputLeftElement
                  id="priceMaxLeftElement"
                  pointerEvents="none"
                  color="black.300"
                  fontSize="1.1em"
                  children="$"
                />
                <Input
                  id="priceMaxInput"
                  data-testid="priceMaxInput"
                  value={FilterModel.maxPrice}

                  fontSize={"15px"}
                  onKeyDown={(event: any) => {
                    const value = event.target.value;
                    FilterModel.setMaxPriceVal(value);
                  }}
                  onChange={maxPriceOnKeyDown}
                />
              </InputGroup>
            </div>
            <div className="range-slider-div" >
              <RangeSlider
              key={FilterModel.minPriceFilter+FilterModel.maxPriceFilter}
                id="priceRangeSlider"
                aria-label={["min", "max"]}
                defaultValue={[parseInt(FilterModel.minPriceFilter.replace(",", "")), FilterModel.maxPriceFilter == "100,000+" ? 100000 : parseInt(FilterModel.maxPriceFilter.replace(",", ""))]}
                min={0}
                max={100000}
                step={1000}
                onChange={(val) => {
                  FilterModel.setMinPriceVal(val[0].toLocaleString().toString().replace(".", ","));
                  FilterModel.setMaxPriceVal(
                    val[1] === 100000
                      ? val[1].toLocaleString().toString().replace(".", ",") + "+"
                      : (val[1] === 0 ? "1000".toLocaleString().replace(".", ",") : val[1].toLocaleString().toString().replace(".", ","))
                  );
                }}
                onChangeEnd={(val) => {
                  FilterModel.setMinPriceFilterVal(val[0].toLocaleString().toString().replace(".", ","));
                  FilterModel.setMaxPriceFilterVal(
                    val[1] === 100000
                      ? val[1].toLocaleString().toString().replace(".", ",") + "+"
                      : (val[1] === 0 ? "1000".toLocaleString().replace(".", ",") : val[1].toLocaleString().toString().replace(".", ","))
                  );
                }}
              >
                <RangeSliderTrack id="priceSliderTrack" bg="red.100">
                  <RangeSliderFilledTrack
                    id="priceSliderFilledTrack"
                    bg="tomato"
                  />
                </RangeSliderTrack>
                <RangeSliderThumb
                  id="priceSliderThumbLeft"
                  index={0}
                  bg="red"
                />
                <RangeSliderThumb
                  id="priceSliderThumbRight"
                  index={1}
                  bg="red"
                />
              </RangeSlider>
            </div>
          </div>
        </div>
        {
          switchBar === 1 ?
            <div className="filter-div">
              <div className="price-div">Mileage</div>
              <div className="content-div3">
                <div className="range-div">
                  <InputGroup id="mileageMinInputGroup">

                    <Input
                      id="mileageMinInput"
                      data-testid="mileageMinInput"
                      value={FilterModel.minMileage}
                      onKeyDown={(event: any) => {
                        const value = event.target.value.toLocaleString().toString().replace(".", ",")
                        FilterModel.setMinMileageVal(value);
                      }}
                      onChange={minMileageChange}
                    />
                  </InputGroup>
                  <div className="to-div">to</div>

                  <InputGroup>

                    <Input
                      id="mileageMaxInput"
                      data-testid="mileageMaxInput"
                      value={FilterModel.maxMileage}
                      onKeyDown={(event: any) => {
                        const value = event.target.value.toLocaleString().toString().replace(".", ",")

                        FilterModel.setMaxMileageVal(value);
                      }}
                      onChange={maxMileageChange}
                    />
                  </InputGroup>
                </div>
                <div className="range-slider-div">
                  <RangeSlider
                    key={FilterModel.minMileageFilter+FilterModel.maxMileageFilter}
                    id="mileageSlider"
                    aria-label={["min", "max"]}
                    defaultValue={[parseInt(FilterModel.minMileageFilter.replace(",", "")), FilterModel.maxMileageFilter == "200,000+" ? 200000 : parseInt(FilterModel.maxMileageFilter.replace(",", ""))]}
                    min={0}
                    max={200000}
                    step={1000}
                    onChange={(val) => {
                      FilterModel.setMinMileageVal(val[0].toLocaleString().toString().replace(".", ","));

                      FilterModel.setMaxMileageVal(
                        val[1] === 200000
                          ? val[1].toLocaleString().toString().replace(".", ",") + "+"
                          : (val[1] === 0 ? "1000" : val[1].toLocaleString().toString().replace(".", ","))
                      );
                    }}
                    onChangeEnd={(val) => {
                      FilterModel.setMinMileageFilterVal(val[0].toLocaleString().toString().replace(".", ","));

                      FilterModel.setMaxMileageFilterVal(
                        val[1] === 200000
                          ? val[1].toLocaleString().toString().replace(".", ",") + "+"
                          : (val[1] === 0 ? "1000".toLocaleString().replace(".", ",") : val[1].toLocaleString().toString().replace(".", ","))
                      );
                    }}
                  >
                    <RangeSliderTrack id="mileageSliderTrack" bg="red.100">
                      <RangeSliderFilledTrack
                        id="mileageSliderFilled"
                        bg="tomato"
                      />
                    </RangeSliderTrack>
                    <RangeSliderThumb
                      id="mileageSliderThumbLeft"
                      index={0}
                      bg="red"
                    />
                    <RangeSliderThumb
                      id="mileageSliderThumbRight"
                      index={1}
                      bg="red"
                    />
                  </RangeSlider>
                </div>
              </div>
            </div>
            : ""
        }
        <Accordion className="filter-div-acordion" allowMultiple>
          {
            switchBar === 1 ?
              <CPOFilter setCPOFilter={setCPOFilter}></CPOFilter> : null
          }
          {
            switchBar === 1 ?
              <ONlineDealers setONlineDealersFilter={setONlineDealersFilter}></ONlineDealers> : null
          }
          <ExteriorColor setExteriorColorFilter={setExteriorColorFilter}></ExteriorColor>
          <PopularFeatures setPopularFeaturesFilter={setPopularFeaturesFilter}></PopularFeatures>
          {
            switchBar === 1 ?
              <ConditionandHistory setConditionandHistoryFilter={setConditionandHistoryFilter}></ConditionandHistory> : null
          }

          <Transmission setTransmissionFilter={setTransmissionFilter}></Transmission>
          <DriveType setDriveTypeilter={setDriveTypeilter}></DriveType>
          <Engine setEngineFilter={setEngineFilter}></Engine>
          <InteriorColor setInteriorColorFilter={setInteriorColorFilter} switchBar={switchBar}></InteriorColor>
          <FuelType setFuelTypeFilter={setFuelTypeFilter}></FuelType>
          <BedLength setBedLengthFilter={setBedLengthFilter}></BedLength>
          <CabType setCabTypeFilter={setCabTypeFilter}></CabType>
          <RoofHeight setRoofHeightFilter={setRoofHeightFilter}></RoofHeight>

        </Accordion>

        {/* <div className="filter-div2">
          <div className="accordion-div">
            <div className="title-div">Condition and History</div>
            <img className="accordionicon" alt="" src="../accordionicon.svg" />
          </div>
        </div> */}

      </div>
      <Button style={{ marginTop: 10 }} onClick={() => { setFilterClear(filterClear + 1) }} className="filterDeskButton" aria-label="Clear filters">Clear filters</Button>
    </Fragment>
  );
};

export default Filters;
