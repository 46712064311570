import "./Header.css";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react'
import { HamburgerIcon } from "@chakra-ui/icons";
import { NavLink, useHistory } from "react-router-dom";
const Header = () => {
  let history = useHistory();
  return (
    <div id="header-div1" className="hdr-header-div1">
      <div className="hdr-iconbutton-div2">
        <Menu id="hdr-menu">
          <MenuButton colorScheme='none' as={Button} rightIcon={<HamburgerIcon />}>
          </MenuButton>
          <MenuList>
            <MenuItem textColor="black">Buying Guides</MenuItem>
            <MenuItem textColor="black">Deals</MenuItem>
            <MenuItem textColor="black">Car Reviews</MenuItem>
            <MenuItem textColor="black">Community</MenuItem>
            <MenuItem textColor="black">Cars A-Z</MenuItem>
          </MenuList>
        </Menu>
      </div>
      
      <img className="hdr-logo-cartalk-icon1" alt="Car Talk icon" onClick={() =>  history.push('/')} src="../logocartalk1.svg" />

      <div className="hdr-headernavbar-div">
        <div className="hdr-navitem-div">
          <div className="hdr-best-deal-div"><p aria-label="Buying Guides">Buying Guides</p></div>
        </div>
        <div className="hdr-navitem-div">
          <div className="hdr-best-deal-div"><p aria-label="Deals">Deals</p></div>
        </div>
        <div className="hdr-navitem-div">
          <div className="hdr-best-deal-div"><p aria-label="Car Reviews">Car Reviews</p></div>
        </div>
        <div className="hdr-navitem-div">
          <div className="hdr-best-deal-div"><p aria-label="Community">Community</p></div>
        </div>
        <div className="hdr-navitem-div">
          <div className="hdr-best-deal-div"><p aria-label="Cars A-Z">Cars A-Z</p></div>
        </div>
      </div>
      <div className="header-div-menu">

        <Menu id="hdr-menu">
          <MenuButton colorScheme='none' as={Button} rightIcon={<HamburgerIcon />}>
          </MenuButton>
          <MenuList>
            <MenuItem textColor="black"><span aria-label="Menu 1">Menu 1</span></MenuItem>
            <MenuItem textColor="black"><span aria-label="Menu 2">Menu 2</span></MenuItem>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
