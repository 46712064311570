import "./CarBestDeal.css";
import { useHistory } from "react-router-dom";
import CarList from "../../../Model/CarListModel";
import { Fragment, useState } from "react";
import CarImageDetail from "../CarDetail/ImageDetail";
import CarGraph from "../CarGraph/CarGraph";

interface Tester {
  screenWidth: number;
  carList: CarList[];
  favoriteListData: number[];
  page: number;
  setFavoriteId: any;
  removedListData: number[];
  setRemovedId: any;
}

const CarBestDeal = (props: Tester) => {
  let history = useHistory();
  const { page, carList, favoriteListData, setFavoriteId, removedListData, setRemovedId, screenWidth } = props;
  const [modalState, setModalState] = useState(false);
  const [modalKey, setModalKey] = useState(0);

  const imageDetail = (value: boolean) => {
    setModalKey(modalKey + 1);
    setModalState(value);
  };

  const favoriteCar = (value: number) => {
    setFavoriteId(value);
  };

  const remove = (id: number) => {
    setRemovedId(id);
  }

  const carDetail = (value: number) => {
    if (!(carList.find(x => x.id == value)?.sold)) {
      window.scrollTo(0, 0);
      history.push("/detail/" + value)
    }
  };

  return (
    <Fragment>
      <CarImageDetail key={modalKey} modalState={modalState} imageDetail={imageDetail} />
      <div id="graph" className="graph">      <CarGraph ></CarGraph>
        <h1>Top Deals</h1>
        <br /></div>

      <div id="grid3" className="grid3">
        {carList.filter(f => !removedListData.includes(f.id)).map((car, index) =>
          index >= 15 * (page - 1) && index < 15 * page ? <Fragment key={index} >
            <div id="div8" onClick={() => carDetail(car.id)} className={(index % (screenWidth > 1020 ? 3 : 2) !== 0) ? screenWidth > 1020 ? "div8  div8-margin" : "div8-2  div8-margin" : screenWidth > 1020 ? "div8" : "div8-2"}>
              <div id="searchCardListing" className="searchCardListing">
                <div className="image">
                  {car.sold === false ? (
                    <img
                      className="imageIcon"
                      alt="car icon"
                      src={car.image}
                      onClick={() => imageDetail(true)}
                    />
                  ) : (
                    <div className="soldG-container">
                      <img
                        className="imageIcon-sold"
                        alt="sold car icon"
                        src={car.image}
                        onClick={() => carDetail(car.id)}
                      />
                      <h1 className="soldG-txt">Sold</h1>
                    </div>
                  )}
                  <div
                    className="saveButton"
                    onClick={() => favoriteCar(car.id)}
                  >
                    <img
                      className="iconCarGrd"
                      alt="car icon"
                      src={
                        favoriteListData.indexOf(car.id) !== -1
                          ? "../iconheartActive_1.svg"
                          : "../iconheart.svg"
                      }
                    />
                  </div>
                  <div className="removeButton">
                    <img
                      onClick={() => remove(car.id)}
                      className="iconCarGrd"
                      alt="car icon"
                      src="../iconclose.svg"
                    />
                  </div>
                </div>
                <div className="content4" onClick={() => carDetail(car.id)}>
                  <b className="yearMakeModelTrim">
                    {car.year} {car.make}{" "}
                    {car.model} {car.trim}{" "}
                  </b>
                  <div className="main">
                    <div className="dataList">
                      <div className="dataItem">
                        <img
                          className="iconCarGrd"
                          alt="car icon"
                          src="../iconspeedometer.svg"
                        />
                        <div className="miles">
                          {car.miles} miles
                        </div>
                      </div>
                      <div className="dataItem1">
                        <img
                          className="iconCarGrd"
                          alt="car icon"
                          src="../iconlocation1.svg"
                        />
                        <div className="content5">
                          <div className="milesAway">
                            {car.distance}
                          </div>
                          <div className="richmondVA">
                            {" "}
                            {car.location}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="price1">
                      {car.goodDeal === true ? (
                        <div className="tag">
                          <img
                            className="goldenWrenchIcon"
                            alt="car icon"
                            src="../goldenwrenchicon.svg"
                          />
                          <img className="icon5" alt="" src="../icon5.svg" />
                          <div className="label1"><p aria-label="Good Deal">Good Deal</p></div>

                          <img
                            className="iconclose1"
                            alt="close icon"
                            src="../iconclose1.svg"
                          />
                        </div>
                      ) : null}

                      <b className="b">
                        {car.priceunit}
                        {car.price}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Fragment> : null
        )}
      </div>

      <div id="grid2" className="grid2">
        {carList.filter(f => !removedListData.includes(f.id)).map((car, index) =>
          index >= 15 * (page - 1) && index < 15 * page ? (
            <div id="div7" key={index} className="div7" onClick={() => carDetail(car.id)}>
              <div id="searchCardListing" className="searchCardListing">
                <div className="image">
                  {/* <img
                    className="imageIcon"
                    alt=""
                    src={car.image}
                    onClick={() => carDetail(car.id)}
                  /> */}
                  {car.sold === false ? (
                    <img
                      className="imageIcon"
                      alt="car icon"
                      src={car.image}
                      onClick={() => imageDetail(true)}
                    // onClick={() => carDetail(car.id)}
                    />
                  ) : (
                    <div className="soldG-container">
                      <img
                        className="imageIcon-sold"
                        alt="sold car icon"
                        src={car.image}
                      // onClick={() => carDetail(car.id)}
                      />
                      <h1 className="soldG-txt"> <p aria-label="Sold">Sold</p></h1>
                    </div>
                  )}
                  <div
                    className="saveButton"
                    onClick={() => favoriteCar(car.id)}
                  >
                    <img
                      className="iconCarGrd"
                      alt="car icon"
                      src={
                        favoriteListData.indexOf(car.id) !== -1
                          ? "../iconheartActive_1.svg"
                          : "../iconheart.svg"
                      }
                    />
                  </div>
                  <div className="removeButton">
                    <img className="iconCarGrd"
                      onClick={() => remove(car.id)}
                      alt="car icon"
                       src="../iconclose.svg" />
                  </div>
                </div>
                <div className="content4">
                  <b className="yearMakeModelTrim">
                    {car.year} {car.make}{" "}
                    {car.model} {car.trim}{" "}
                  </b>
                  <div className="main">
                    <div className="dataList">
                      <div className="dataItem">
                        <img
                          className="iconCarGrd"
                          alt="car icon"
                          src="../iconspeedometer.svg"
                        />
                        <div className="miles">
                          {car.miles} miles
                        </div>
                      </div>
                      <div className="dataItem1">
                        <img
                          className="iconCarGrd"
                          alt="car icon"
                          src="../iconlocation1.svg"
                        />
                        <div className="content5">
                          <div className="milesAway">
                            {car.distance}
                          </div>
                          <div className="richmondVA">
                            {" "}
                            {car.location}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="price1" className="price1">
                      {car.goodDeal === true ? (
                        <div className="tag">
                          <img
                            className="goldenWrenchIcon"
                            alt="car icon"
                            src="../goldenwrenchicon.svg"
                          />
                          <img className="icon5" alt="" src="../icon5.svg" />
                          <div className="label1"><p aria-label="Good Deal">Good Deal</p></div>

                          <img
                            className="iconclose1"
                            alt="close icon"
                            src="../iconclose1.svg"
                          />
                        </div>
                      ) : null}

                      <b className="b">
                        {car.priceunit}
                        {car.price}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        )}
      </div>
    </Fragment>
  );
};

export default CarBestDeal;
