import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Select } from "@chakra-ui/select";
import { useState } from "react";
import "./FilterLocation.css";

interface LocationItems {
  zipCode: string;
  Distance: string;
  setZipCode: any;
  setDistance: any;
}
const FilterLocation = (props: LocationItems) => {


  const { zipCode, setZipCode, Distance, setDistance } = props;
  const [isError, setIsError] = useState(true);
  // const [zipCode, setZipCode] = useState("");
  // const [Distance, setDistance] = useState("50");

  const changeZip = (event: any) => {
    event.target.value.length < 3 ? setIsError(true) : setIsError(false);
    setZipCode(event.target.value)
  };
  return (

    <div style={{ marginTop: 25 }}>
      <FormControl isInvalid={isError}>
        <FormLabel aria-label="City or ZIP">City or ZIP</FormLabel>
        <Input placeholder="City or ZIP" defaultValue={zipCode} onChange={(event) => changeZip(event)}

        />
        <FormErrorMessage aria-label="You must enter at least 3 characters">You must enter at least 3 characters</FormErrorMessage>
      </FormControl>

      <FormControl mt={4}>
        <FormLabel aria-label="Distance">Distance</FormLabel>

        <Select
          variant="filled"
          defaultValue={Distance}
          id="selectMake"
          onChange={(event) => setDistance(event.target.value)}
          style={{ backgroundColor: "white", borderColor: "#F7F1F1" }}
        >
          <option value="5" aria-label="miles">5 miles</option>
          <option value="10" aria-label="10 miles">10 miles</option>
          <option value="25" aria-label="25 miles">25 miles</option>
          <option value="50" aria-label="50 miles">50 miles</option>
          <option value="75" aria-label="75 miles">75 miles</option>
          <option value="100" aria-label="100 miles">100 miles</option>
          <option value="150" aria-label="150 miles">150 miles</option>
          <option value="200" aria-label="200 miles">200 miles</option>
          <option value="250" aria-label="250 miles">250 miles</option>
          <option value="500" aria-label="500 miles">500 miles</option>
          <option value="All" aria-label="All">All</option>
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterLocation;
