import './App.css';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import SearchList from './components/FilterList/SearchList';
import CarDetail from './components/pages/CarDetail/CarDetail';
import { useEffect, useState } from 'react';
import Header from './components/pages/Header/Header';
import Footeer from './components/pages/Footer/Footer';

const history = createBrowserHistory();

function App() {
  return (

    <Router>
      <div id="cl1-search-list-desktop" className="cl1-search-list-desktop">
        <Header />

        <Route exact path="/" component={SearchList} />
        <Route exact path="/:switchBar" component={SearchList} />
        <Route exact path="/detail/:id" component={CarDetail} />
        <Footeer />
      </div>
    </Router>
  );
}

export default App;