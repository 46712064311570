export enum EngineEnum {
    Cylinder10="10 Cylinder",
    Cylinder12="12 Cylinder",
    Cylinder3="3 Cylinder",
    Cylinder4 ="4 Cylinder",
    Cylinder6  ="6 Cylinder",
    Cylinder8  ="8 Cylinder",
    Electric ="Electric",
    Hybrid ="Hybrid",
    Hydrogen ="Hydrogen",
    Rotary ="Rotary",
    Unknown ="Unknown",
  }

  export function getEngineEnum(){
    return Object.keys(EngineEnum) as Array<keyof typeof EngineEnum>;
  }