import "./FilterItems.css";
import {
    Box,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Checkbox,
    CheckboxGroup,
} from "@chakra-ui/react";
import { NotAllowedIcon } from '@chakra-ui/icons'
import { getCarColors, CarColors } from "../Enums/CarColors";
import { getInteriorColorEnum, InteriorColorEnum } from "../Enums/InteriorColorEnum";

const InteriorColor = (props: any) => {

    const { setInteriorColorFilter,switchBar } = props;
    function onChange(e: any) {
        setInteriorColorFilter(e.target.checked)
    }

    return (
        <AccordionItem className="filter-div-acordion-item">
            <h2>
                <AccordionButton className="acordion-button">
                    <Box as="span" flex='1' textAlign='left' height={10}>
                    Interior Color
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} className="acordion-panel">
                <CheckboxGroup>

                    {
                        switchBar===1?
                        getCarColors().map((key, index) => (
                            <div key={index} className="checkbox-color-div">
                                <Checkbox onChange={(e) => onChange(e)} colorScheme='red' size='lg' className="color-checkbox">
                                    {CarColors[key] === "" ?
                                        <NotAllowedIcon /> : <i className="rounded-full" style={{ backgroundColor: CarColors[key] }}></i>
                                    } {key}
                                </Checkbox>
                            </div>
                        )):
                        getInteriorColorEnum().map((key, index) => (
                            <div key={index} className="checkbox-color-div">
                                <Checkbox onChange={(e) => onChange(e)} colorScheme='red' size='lg' className="color-checkbox">
                                    {InteriorColorEnum[key] === "" ?
                                        <NotAllowedIcon /> : <i className="rounded-full" style={{ backgroundColor: InteriorColorEnum[key] }}></i>
                                    } {key.replace("_","/")}
                                </Checkbox>
                            </div>
                        ))

                    }
                </CheckboxGroup>
            </AccordionPanel>
        </AccordionItem>
    );
};

export default InteriorColor;
