import React from "react";
import ReactDOM from "react-dom/client";

import "./global.css";
import "./responsive.css";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { selectTheme } from "./Theme/Component/select";
import App from "./App";
import Header from "./components/pages/Header/Header";
import Footer from "./components/pages/Footer/Footer";
const theme = extendTheme({
  components: {
    Select: selectTheme,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ChakraProvider theme={theme}>

      <React.StrictMode>
        <App />
      </React.StrictMode>
      

  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
