import "./CarDetail.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useHistory, useParams } from 'react-router-dom'
import { useState, Fragment, useEffect, useLayoutEffect } from "react";
import CarImageDetail from "./ImageDetail";
import {
  Input,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Box,
  FormErrorMessage,
  Checkbox,
} from "@chakra-ui/react";
import axios, { AxiosResponse } from "axios";
import { Cookies } from "react-cookie";
import CarListModel from "../../../Model/CarListModel";
import { Axios } from "axios";

import data from '../../FilterList/data.json';
const CarDetail = () => {
  const [carList, setCarList] = useState<CarListModel[]>([]);
  const [carDetail, setCarDetail] = useState<CarListModel>();
  const [imageList, setImageList] = useState<string[]>(["../7.jpg", "../6.jpg", "../4.jpg", "../3.jpg"]);
  const [screenWidth, setScreenWidth] = useState(window.screen.availWidth);
  const params = JSON.stringify(useParams());
  useEffect(() => {
    setCarList(data);
    // axios("http://localhost:3000/list.json").then((res) => {

    // });

  }, []);
  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateSize);
    updateSize();

  }, []);//Bu Fonskiyon Responsive Tetiklemek için
  useEffect(() => {
    var data = carList.find(x => x.id == Number(JSON.parse(params).id))
    setCarDetail(data)
  }, [carList]);


  const [favoriteList, setFavoriList] = useState<Number[]>([]);
  const [carFavorite, setCarFavorite] = useState(false);
  const cookies = new Cookies();
  let history = useHistory();
  useEffect(() => {
    let data = cookies.get('FavoriteList');

    if (data && data.indexOf(Number(JSON.parse(params).id)) != -1) {
      setCarFavorite(true)
    }
    if (data) {
      setFavoriList(cookies.get('FavoriteList'))
    }

  }, []);

  const [modalState, setModalState] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [shareModal, setShareModal] = useState(false);
  const [isError, setIsError] = useState(true);
  const [imageKey, setImageKey] = useState(0);
  const imageDetail = (value: boolean) => {
    setModalKey(modalKey + 1);
    setModalState(value);
  };
  const FavoriteCar = () => {
    let favoriteDump = favoriteList;
    if (carFavorite) {
      favoriteDump.splice(Number(JSON.parse(params).id), 1);
    }
    else {
      favoriteDump.push(Number(JSON.parse(params).id));
    }
    cookies.set('FavoriteList', JSON.stringify(favoriteDump));
    setCarFavorite(!carFavorite)
  };

  const imageChange = (value: number) => {
    const dumyDataOld = imageList[0];
    const newIndex = imageList[value];
    let data = imageList;
    data[0] = newIndex;
    data[value] = dumyDataOld;
    setImageList(data);
    setImageKey(imageKey + 1);
  };


  const carLocation = () => {
    return (<div className="dtl-hero">
      <div className="dtl-link">
        <Link to='/' >
          <b className="dtl-richmond-va-64-miles-away" >« Back</b>
        </Link>
        <img
          className="dtl-iconexternal-link"
          alt=""
          src="../iconexternallink.svg"
        />
      </div>
      <div className="dtl-content">
        <b className="dtl-nissan-sentra-sv">{carDetail?.year} {carDetail?.make} {carDetail?.model} </b>
        <div className="dtl-details">
          <div className="dtl-data">
            <div className="dtl-div">
              <div className="dtl-iconlist">
                <div>        
                  <img className="dtl-icon" alt="car icon" src="../iconcar.svg" ></img>
                </div>
                <div className="dtl-nav-item">Used Sedan</div>
                <div className="dtl-nav-item">&nbsp; · SV CVT </div>
                {screenWidth > 800 ? <div className="dtl-nav-item">&nbsp; · VIN: 3N1AB7AP4KY307816</div> : <div className="dtl-nav-item-vin">VIN: 3N1AB7AP4KY307816</div>}
              </div>
            </div>
            <div className="dtl-iconlist">
              <img className="dtl-icon" alt="location icon" src="../iconlocation.svg" />
              <div className="dtl-richmond-va-64-miles-away">
                <span className="dtl-richmond-va"> {carDetail?.location}</span>
                <span> ({carDetail?.miles} miles away)</span>
              </div>
            </div>
          </div>
          {screenWidth > 800 ? <div className="dtl-buttons">
            <div className="dtl-button">
              <div className="dtl-title" onClick={(e) => { setShareModal(true) }} ><span aria-label="Share">Share</span></div>
            </div>
            <div className="dtl-button2" onClick={(e) => { FavoriteCar() }}>
              <img className="dtl-trending-down-icon" alt="heart Active White" src={carFavorite ? "../heartActiveWhite.svg" : "../icon1.svg"} />
              <div className="dtl-title"><span aria-label="Save">Save</span></div>
            </div>
          </div> : <div className="dtl-buttons">
            <div className="dtl-button2" onClick={(e) => { FavoriteCar() }}>
              <img className="dtl-trending-down-icon" alt="" src={carFavorite ? "../heartActiveWhite.svg" : "../icon1.svg"} />
              <div className="dtl-title"><span aria-label="Save">Save</span></div>
            </div>
            <div className="dtl-button">
              <div className="dtl-title" onClick={(e) => { setShareModal(true) }} ><span aria-label="Share">Share</span></div>
            </div>

          </div>}

        </div>

      </div>
    </div>)
  }

  const carRail = () => {
    return (<div className="dtl-rail">
      <div className="dtl-lead-form">
        <div className="dtl-price">
          <div className="dtl-name">
            <b className="dtl-b">${carDetail?.price}</b>
            <div className="dtl-tag">
              <img
                className="dtl-goldenwrenchicon"
                alt="golden wrench icon"
                src="../goldenwrenchicon.svg"
              />
              <img
                className="dtl-trending-down-icon"
                alt="trending down icon"
                src="../icon3.svg"
              />
              <div className="dtl-title"><span aria-label="Good Deal">Good Deal</span></div>
              <img
                className="dtl-iconthumbs-up"
                alt="Thumbs up icon"
                src="../iconthumbsup.svg"
              />
              <img className="dtl-iconclose" alt="Close icon" aria-label="Close" src="../iconclose.svg" />
            </div>
          </div>
          <div className="dtl-price-drop1">
            <div className="dtl-icon4">
              <img
                className="dtl-trending-down-icon"
                alt=""
                src="../trendingdown.svg"
              />
            </div>
            <div className="dtl-text2">
              <div className="dtl-richmond-va-64-miles-away">
                <b>
                  {`Price Drop: `}
                  <span className="dtl-span">$19,500</span>
                </b>
              </div>
              <div className="dtl-this-car-is-likely-to-sell-in">
                This car is likely to sell in 7 days
              </div>
            </div>
          </div>
          <div className="dtl-chart">
            <div className="dtl-x-axis">
              <div className="dtl-k">10/25</div>
              <div className="dtl-k">11/4</div>
              <div className="dtl-k">11/13</div>
            </div>
            <img className="dtl-chart-icon" alt="" src="../chart.svg" />
            <div className="dtl-y-axis">
              <div className="dtl-k">$20k</div>
              <div className="dtl-k">$19.6k</div>
              <div className="dtl-k">$19.2k</div>
              <div className="dtl-k">$18.8k</div>
              <div className="dtl-k">$18.4k</div>
              <div className="dtl-k">$18k</div>
            </div>
          </div>
        </div>
        <div className="dtl-form">
          <div className="dtl-name">
            <div className="dtl-formcontrol">
              <div className="dtl-label1">
                <div className="dtl-nav-item"><span aria-label="First Name">First Name</span></div>
                <b className="dtl-b1">*</b>
              </div>

              <Input />

              <div className="dtl-formhelpertext"><span aria-label="Helper Text">Helper Text</span></div>
            </div>
            <div className="dtl-formcontrol">
              <div className="dtl-label1">
                <div className="dtl-nav-item"><span aria-label="Last Name">Last Name</span></div>
                <b className="dtl-b1">*</b>
              </div>
              <Input />
              <div className="dtl-formhelpertext"><span aria-label="Helper Text">Helper Text</span></div>
            </div>
          </div>
          <div className="dtl-text">
            <div className="dtl-label1">
              <div className="dtl-nav-item"><span aria-label="Email address">Email address</span></div>
              <b className="dtl-b1">*</b>
            </div>  <Input placeholder="name@email.com" />

            <div className="dtl-formhelpertext"><span aria-label="Helper Text">Helper Text</span></div>
          </div>
          <div className="dtl-text">
            <div className="dtl-label1">
              <div className="dtl-nav-item"><span aria-label="Phone number">Phone number</span></div>
              <b className="dtl-b1">*</b>
            </div>
            <Input type='tel' />
            <div className="dtl-formhelpertext"><span aria-label="Helper Text">Helper Text</span></div>
          </div>
          <div className="dtl-checkbox">
            <Checkbox defaultChecked></Checkbox>
            <div className="dtl-content1">
              <div className="dtl-customcontent" />
              <div className="dtl-label5">
                {`I accept CarTalk's `}
                <span className="dtl-terms-of-use" aria-label="Terms of Use">Terms of Use</span>
                {` and `}
                <span className="dtl-terms-of-use" aria-label="Privacy Policy">Privacy Policy</span>.
              </div>
            </div>
          </div>
          <div className="dtl-button6" onClick={() => alert("Link Request Information")}>
            <div className="dtl-title"><span aria-label="Request Information">Request Information</span></div>
          </div>
        </div>
      </div>
    </div>)
  }
  const routeAllSavedCarList = () => {
    const location = {
      pathname: '/Saved',
      state: { switchBar: 3 }
    }
    history.push(location)
    window.scroll(0, 0)
  };

  return (
    <Fragment>
      <CarImageDetail key={modalKey} modalState={modalState} imageDetail={imageDetail} />

      {/* <Header></Header> */}
      {screenWidth > 800 ? carLocation() : null}
      <div className="dtl-body">
        <div className="dtl-main-content">

          <div className="dtl-photogrid" key={imageKey}>
            <div className="dtl-div4" onClick={() => imageChange(1)}>
              <img src={imageList[1]} className="dtl-overlay" alt="car image"></img>
            </div>
            <div className="dtl-div5" onClick={() => imageChange(2)}>
              <img src={imageList[2]} className="dtl-overlay" alt="car image"></img>
            </div>
            <div className="dtl-div6" onClick={() => imageChange(3)}>

              <img src={imageList[3]} className="dtl-overlay" alt="car image"></img>
            </div>
            <div className="dtl-div7">
              <img src={imageList[0]} className="dtl-overlay" alt="car image"></img>

            </div>
            <div className="dtl-button4" >
              <div className="dtl-title" onClick={() => imageDetail(true)}> <span aria-label="Show all photos">Show all photos</span></div>
            </div>
          </div>

          {screenWidth <= 800 ? carLocation() : null}
          {screenWidth <= 800 ? carRail() : null}
          <div className="dtl-about">
            <div className="dtl-text">
              <b className="dtl-dealer-description" aria-label="Dealer Description">Dealer Description</b>
              <div className="dtl-ut-id-nunc-nec-velit-mattis-lo">
                <p aria-label="
                Ut id nunc nec velit mattis lobortis. Duis suscipit vitae odio
                sed vulputate. Aenean sed sapien ante. Curabitur imperdiet et
                est quis bibendum. Aenean suscipit euismod risus ac condimentum.
                Morbi dictum est in massa egestas, vitae tempor ex cursus. Sed
                bibendum pretium facilisis. Fusce sit amet varius nisl. Praesent
                a lacus convallis, dictum lorem eget, faucibus est. Donec
                tincidunt vulputate dolor, a tincidunt nunc efficitur fringilla.
                Suspendisse potenti. Proin condimentum est sit amet ipsum auctor
                porta. Donec semper sollicitudin dolor nec scelerisque.">
                  
                Ut id nunc nec velit mattis lobortis. Duis suscipit vitae odio
                sed vulputate. Aenean sed sapien ante. Curabitur imperdiet et
                est quis bibendum. Aenean suscipit euismod risus ac condimentum.
                Morbi dictum est in massa egestas, vitae tempor ex cursus. Sed
                bibendum pretium facilisis. Fusce sit amet varius nisl. Praesent
                a lacus convallis, dictum lorem eget, faucibus est. Donec
                tincidunt vulputate dolor, a tincidunt nunc efficitur fringilla.
                Suspendisse potenti. Proin condimentum est sit amet ipsum auctor
                porta. Donec semper sollicitudin dolor nec scelerisque.
                </p>
              </div>
            </div>
            <div className="dtl-price-drop">
              <div className="dtl-iconbutton1" onClick={() => FavoriteCar()}>
                <img className="dtl-icon" alt="heart icon"
                  src={carFavorite ? "../iconheartActive_1.svg" : "../icon2.svg"} />
              </div>
              <div className="dtl-text1">
                <div className="dtl-considering-purchasing-this-20">
                  Considering purchasing this {carDetail?.year} {carDetail?.make} {carDetail?.model}? Save it to
                  your list.
                </div>
                <div className="dtl-link2" onClick={() => routeAllSavedCarList()}>
                  <b className="dtl-k">View all saved cars »</b>
                  <img
                    className="dtl-iconthumbs-up"
                    alt="thımbs up icon"
                    src="../iconexternallink1.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="dtl-highlights">
            <b className="dtl-vehicle-specs" aria-label="Vehicle Specs">Vehicle Specs</b>
            <div className="dtl-columns">
              <div className="dtl-div8">
                <div className="dtl-iconlist1">
                  <img
                    className="dtl-iconspeedometer"
                    alt="icon speed ometer"
                    src="../iconspeedometer.svg"
                  />
                  <div className="dtl-miles">45,797 miles</div>
                </div>
                <div className="dtl-iconlist1">
                  <img
                    className="dtl-iconspeedometer"
                    alt="icon speed ometer"
                    src="../iconmoney.svg"
                  />
                  <div className="dtl-miles">${carDetail?.price}</div>
                </div>
              </div>
              <div className="dtl-div8">
                <div className="dtl-iconlist1">
                  <img
                    className="dtl-iconspeedometer"
                    alt="icon speed ometer"
                    src="../icongaspump.svg"
                  />
                  <div className="dtl-miles"><p>29 City / 37 Hwy</p></div>
                </div>
                <div className="dtl-iconlist1">
                  <img
                    className="dtl-iconspeedometer"
                    alt="icon speed ometer"
                    src="../iconengine.svg"
                  />
                  <div className="dtl-miles"><p>1.8L Inline-4 Gas</p></div>
                </div>
              </div>
              <div className="dtl-div8">
                <div className="dtl-iconlist1">
                  <img
                    className="dtl-iconspeedometer"
                    alt="icon speed ometer"
                    src="../icondrivetrain.svg"
                  />
                  <div className="dtl-miles"><p>FWD</p></div>
                </div>
                <div className="dtl-iconlist1">
                  <img
                    className="dtl-iconspeedometer"
                    alt="icon speed ometer"
                    src="../icontransmission.svg"
                  />
                  <div className="dtl-miles"><p>Automatic</p></div>
                </div>
              </div>
              <div className="dtl-div8">
                <div className="dtl-iconlist1">
                  <div className="dtl-rectangle-div" />
                  <div className="dtl-miles"> <p>Exterior: Black</p></div>
                </div>
                <div className="dtl-iconlist1">
                  <div className="dtl-rectangle-div1" />
                  <div className="dtl-miles"><p>Interior: Charcoal</p></div>
                </div>
              </div>
            </div>
          </div>

          <div className="dtl-specialties">


            <b className="dtl-vehicle-features"  aria-label="Vehicle Features">Vehicle Features</b>
            <div className="dtl-list" >

              <div className="dtl-div8">

                <div className="dtl-div13">

                  <div className="dtl-items"><p>6 Speakers</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Radio data system</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Automatic temperature control</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Power steering</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items">
                    <p>Steering wheel mounted audio controls</p>
                  </div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"> <p>ABS brakes</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Front anti-roll bar</p></div>
                </div>
              </div>
              <div className="dtl-div8">
                <div className="dtl-div13">
                  <div className="dtl-items"><p>6-Speaker Audio System</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Radio: AM/FM/Aux-In Audio System</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Front dual zone A/C</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Power windows</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Speed-sensing steering</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Dual front impact airbags</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items">
                    <p>Front wheel independent suspension</p>
                  </div>
                </div>
              </div>
              <div className="dtl-div8">
                <div className="dtl-div13">
                  <div className="dtl-items"><p>AM/FM radio: SiriusXM</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Air Conditioning</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Rear window defroster</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Remote keyless entry</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Traction control</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Dual front side impact airbags</p></div>
                </div>
                <div className="dtl-div13">
                  <div className="dtl-items"><p>Low tire pressure warning</p></div>
                </div>
              </div>
            </div>
            <div className="dtl-accordion">
              <div className="dtl-title"><p>See all features</p></div>
              <img
                className="dtl-accordionicon"
                alt=""
                src="../accordionicon.svg"
              />
            </div>
          </div>
        </div>
        {screenWidth > 800 ? carRail() : null}
      </div>
      {/* <Footer></Footer> */}

      <Modal
        isOpen={shareModal}
        onClose={() => setShareModal(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div style={{ marginTop: 25 }}>
              <FormControl isInvalid={isError}>
                <FormLabel>Url Link</FormLabel>
                <Input disabled={true} value={"https://www.cartalk.com/carDetail/10"} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>E-Mail Address</FormLabel>
                <Input />
                <FormErrorMessage>You must enter at least 3 characters</FormErrorMessage>
              </FormControl>
            </div>
            &nbsp;
          </ModalBody>
          <hr></hr>
          <ModalFooter>

            <Box w='30%' >
              <Button colorScheme="red" onClick={() => alert("Link Send Success.")}>Share Link</Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Fragment>
  );
};


export default CarDetail;

