import { Center } from "@chakra-ui/react";
import "./Footer.css";

const Footeer = () => {
  return (
    <div id="footer-div" className="footer-div">
      
      <div id="content-div" className="content-div">
        <div id="disclaimer-div" className="disclaimer-div">
          <a href="/">
            <img
              className="logo-cartalk-icon"
              alt="Car talk icon"
              aria-label="Car talk icon"
              src="../logocartalk.svg"
            />
          </a>
          <div
            id="cartalkcom-is-a-production-of"
           
          >
            <span aria-aria-label="
            Cartalk.com is a production of Cartalk Digital Inc. 
            We offer unbiased reviews and advice,
            bad jokes and a great community for
            car owners and shoppers. Happy driving and remember... don't drive
            like my brother.">
              Cartalk.com is a production of Cartalk Digital Inc.
              We offer unbiased reviews and advice,
              bad jokes and a great community for
              car owners and shoppers. Happy driving and remember... don't drive
              like my brother.
            </span>
          </div>
        </div>
        <div id="quick-links-div" className="quick-links-div">
          
          <b className="popular-topics-b" aria-label="Quick Links">Quick Links</b>
      
          <div id="list-div" className="list-div">
            <div id="list-div1" className="list-div1">
              <div >
                <div className="quick-links-b">
                  <a aria-label="Home" href="#">Home</a>
                </div>
              </div>
              <div >
                <div className="quick-links-b">
                  <a aria-label="Car Reviews" href="#">Car Reviews</a>
                </div>
              </div>
              <div >
                <div className="quick-links-b">
                  <a aria-label="Newsletter" href="#">Newsletter</a>
                </div>
              </div>
              <div >
                <div className="quick-links-b">
                  <a aria-label="Community" href="#">Community</a>
                </div>
              </div>

            </div>
            <div id="list-div2" className="list-div1">
              <div >
                <div className="quick-links-b">
                  <a aria-label="Cars A-Z" href="#">Cars A-Z</a>
                </div>
              </div>
              <div >
                <div className="quick-links-b">
                  <a aria-label="This Week’s Show" href="#">This Week’s Show</a>
                </div>
              </div>
              <div >
                <div className="quick-links-b">
                  <a aria-label="Repair Shops" href="#">Repair Shops</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="popular-topics-div" className="popular-topics-div">
          <b className="popular-topics-b" aria-label="Popular Topics">Popular Topics</b>
          <div id="row-div" className="row-div">
            <div className="badge-div">
              <button className="best-deal-div" aria-label="Warranties">Warranties</button>
            </div>
            <div className="badge-div">
              <button className="best-deal-div" aria-label="Tires">Tires</button>
            </div>
            <div className="badge-div">
              <button className="best-deal-div" aria-label="Car Shipping">Car Shipping</button>
            </div>
          </div>
          <div id="row-div2" className="row-div">
            <div className="badge-div">
              <button className="best-deal-div" aria-label="Driver’s Ed">Driver’s Ed</button>
            </div>
            <div className="badge-div">
              <button className="best-deal-div" aria-label="Car Buying">Car Buying</button>
            </div>
            <div className="badge-div">
              <button className="best-deal-div" aria-label="Deals">Deals</button>
            </div>
          </div>
          <div id="row-div3" className="row-div">
            <div className="badge-div">
              <button className="best-deal-div" aria-label="Oil Change">Oil Change</button>
            </div>
            <div className="badge-div">
              <button className="best-deal-div" aria-label="Podcast">Podcast</button>
            </div>
            <div className="badge-div">
              <button className="best-deal-div" aria-label="Car Insurance">Car Insurance</button>
            </div>
          </div>
        </div>
      </div>
      <div id="global-div" className="global-div">
        <div style={{ alignItems: "center" }} >
          <div id="links-div" className="links-div">
            <a className="contact-us-div" href="#" aria-label="Contact Us">Contact Us</a>
            <a className="contact-us-div" href="#" aria-aria-label="Write for Us">Write for Us</a>
            <a className="contact-us-div" href="#" aria-label="Rating Methodology">Rating Methodology</a>
            <a className="contact-us-div" href="#" aria-label="Privacy Policy">Privacy Policy</a>
            <a className="contact-us-div" href="#" aria-label="Site Map">Site Map</a>
          </div>
        </div>

        <div id="disclaimer-div1" className="disclaimer-div1">
          <div className="car-talk-is-a-trademark-of-ta">
            <p aria-label="
            CAR TALK® is a trademark of Tappet Brothers LLC exclusively
            licensed to CarTalk Digital Inc. subject to certain reserved
            rights.">
              CAR TALK® is a trademark of Tappet Brothers LLC exclusively
              licensed to CarTalk Digital Inc. subject to certain reserved
              rights.

            </p>
          </div>
          <div className="car-talk-is-a-trademark-of-ta">
            <p aria-label="SHAMELESS COMMERCE® is a trademark of Tappet Brothers LLC.">
            SHAMELESS COMMERCE® is a trademark of Tappet Brothers LLC.
            </p>
          </div>
          <div className="car-talk-is-a-trademark-of-ta">
            <p aria-label="© 2022 Cartalk Digital Inc.">© 2022 Cartalk Digital Inc.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footeer;
