import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(selectAnatomy.keys);

const outline = definePartsStyle({
  field: {
    fontFamily: "mono",
    fontWeight: "bold",
    borderRadius: 0,
    borderColor: "purple.100",

    _focusWithin: {
      ringColor: "purple.200",
      ring: "2px",
      ringOffset: "1px",
      ringOffsetColor: "purple.100",
      borderColor: "purple.50"
    }
  }
});

const filled = definePartsStyle({
  field: {
    background: "gray.300",
    _dark: {
      background: "gray.600"
    }
  },
  icon: {
    color: "gray.800",
    _dark: {
      color: "gray.200"
    }
  }
});

const flushed = definePartsStyle({
  field: {
    borderBottom: "0px dashed",
    borderColor: "purple.300",
  },
  icon: {
    fontSize: "6xl"
  }
});

const brandPrimary = definePartsStyle({
  field: {
    background: "purple.100",
    border: "1px dashed",
    borderColor: "purple.200",
    borderRadius: "full",
    _dark: {
      background: "purple.600",
    }
  },
  icon: {
    color: "purple.400"
  }
});

const xl = defineStyle({
  fontSize: "lg",
  px: "2",
  h: "12"
});

const sizes = {
  xl: definePartsStyle({ field: xl })
};

export const selectTheme = defineMultiStyleConfig({
  variants: { brandPrimary, outline, filled, flushed },
  sizes
});