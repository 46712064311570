import "./CarList.css";
import { useHistory } from "react-router-dom";
import CarListModel from "../../../Model/CarListModel";
import { Fragment, useState } from "react";
import CarImageDetail from "../CarDetail/ImageDetail";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
} from '@chakra-ui/react'
import { normalize } from "path";
interface Tester {
  carListModel: CarListModel[];
  favoriteListData: number[];
  page: number;
  setFavoriteId: any;
  removedListData: number[];
  setRemovedId: any;
}

const CarList = (props: Tester) => {
  const [modalState, setModalState] = useState(false);
  const [modalKey, setModalKey] = useState(0);

  const imageDetail = (value: boolean) => {
    setModalKey(modalKey + 1);
    setModalState(value);
  };

  let history = useHistory();
  const {
    page,
    carListModel,
    favoriteListData,
    setFavoriteId,
    removedListData,
    setRemovedId,
  } = props;

  const favoriteCar = (value: number) => {
    setFavoriteId(value);
  };
  const remove = (id: number) => {
    setRemovedId(id);
  };
  const carDetail = (value: number) => {
    if (!(carListModel.find(x => x.id == value)?.sold)) {
      window.scrollTo(0, 0);
      history.push("/detail/" + value)
    }
  };

  return (
    <Fragment>
      <CarImageDetail key={modalKey} modalState={modalState} imageDetail={imageDetail} />
      <TableContainer className="content-div4">
        <Table variant='striped' colorScheme={"blackAlpha"}>
          <Thead className="taleHeader">
            <Tr >
              <Th className="TableSave" textTransform={"capitalize"} fontSize={"medium"} ><p aria-label="Save">Save</p></Th>
              <Th className="TablePhoto" textTransform={"capitalize"} fontSize={"medium"}> <p aria-label="Photo">Photo</p>  </Th>
              <Th className="TableYMM" textTransform={"capitalize"} fontSize={"medium"}> <p aria-label="Year Make Model">Year Make Model</p>  </Th>
              <Th className="TablePrice" textTransform={"capitalize"} fontSize={"medium"}> <p aria-label="Miles">Miles</p>  </Th>
              <Th className="TablePrice" textTransform={"capitalize"} fontSize={"medium"}> <p aria-label="">Price</p></Th>
              <Th className="TableSave" textTransform={"capitalize"} fontSize={"medium"}> <p aria-label="Remove">Remove</p></Th>
            </Tr>
          </Thead>
          <Tbody>
            {carListModel
              .filter((f) => !removedListData.includes(f.id)).map((car, index) =>
              index >= 15 * (page - 1) && index < 15 * page ?(
                <Tr className="trs" key={index}>
                    <Td width={"0.5"}>
                      <div
                        className="savebutton-div"
                        onClick={() => favoriteCar(car.id)}
                      >
                        <img
                          className="iconlocation"
                          alt="Location icon"
                          src={
                            favoriteListData.indexOf(car.id) !== -1
                              ? "../iconheartActive_1.svg"
                              : "../iconheart.svg"
                          }
                        />
                      </div>
                    </Td>
                    <Td className="image-icon-td" width={"0.5"} >
                      {car.sold === false ? (
                        <img
                          className="image-icon"
                          alt="image icon"
                          src={car.image}
                          //   onMouseOver={() => imageDetail(true)}
                          onClick={() => imageDetail(true)}
                        />
                      ) : (
                        <div className="sold-container">
                          <img
                            className="image-icon-sold"
                            alt="Solid"
                            src={car.image}
                          />
                          <h1 className="sold-txt">Sold</h1>
                        </div>
                      )}
                    </Td>
                    <Td onClick={() => carDetail(car.id)}>

                      <div id="content-div5" className="content-div5" >
                        <div id="price-div" className="price-div">
                          {car.year} {car.make} {car.model}
                        </div>
                        <div className="richmond-va-64-miles-away">
                          {car.location} ({car.distance})
                        </div>
                      </div>
                    </Td>
                    <Td onClick={() => carDetail(car.id)}>
                      <div className="input-value-div" >{car.miles} miles</div>
                    </Td>
                    <Td onClick={() => carDetail(car.id)}>
                      <div className="input-value-div" >
                        {car.priceunit}{car.price}
                      </div>
                    </Td>
                    <Td width={"0.5"}>
                      <div className="savebutton-div">
                        <img
                          onClick={() => remove(car.id)}
                          className="iconlocation"
                          alt=""
                          src="../iconclose.svg"
                        />
                      </div>

                    </Td>
                    
                </Tr >
              ):null
              )
            }
          </Tbody>
        </Table>
      </TableContainer>



      {/* <div id="content-div4" className="content-div4">
        {carListModel
          .filter((f) => !removedListData.includes(f.id))
          .map((car, index) =>
            index >= 15 * (page - 1) && index < 15 * page ? (
              <Fragment key={index}>
                {index % 2 ? (
                  <div key={index} id="listrow-div1" className="listrow-div1">
                    <div
                      className="savebutton-div"
                      onClick={() => favoriteCar(car.id)}
                    >
                      <img
                        className="iconlocation"
                        alt=""
                        src={
                          favoriteListData.indexOf(car.id) !== -1
                            ? "../iconheartActive_1.svg"
                            : "../iconheart.svg"
                        }
                      />
                    </div>
                    {car.sold === false ? (
                      <img
                        className="image-icon"
                        alt=""
                        src={car.image}
                        onMouseOver={() => imageDetail(true)}
                      // onClick={() => carDetail(car.id)}
                      />
                    ) : (
                      <div className="sold-container">
                        <img
                          className="image-icon-sold"
                          alt=""
                          src={car.image}
                          onClick={() => carDetail(car.id)}
                        />
                        <h1 className="sold-txt">Sold</h1>
                      </div>
                    )}
                    <div id="content-div5" className="content-div5" onClick={() => carDetail(car.id)}>
                      <div id="price-div" className="price-div">
                        {car.year} {car.make} {car.model}
                      </div>
                      <div className="richmond-va-64-miles-away">
                        {car.location} ({car.distance})
                      </div>
                    </div>
                    <div className="input-value-div" onClick={() => carDetail(car.id)}>{car.miles} miles</div>
                    <div className="input-value-div" onClick={() => carDetail(car.id)}>
                      {car.priceunit}{car.price}
                    </div>
                    <div className="savebutton-div">
                      <img
                        onClick={() => remove(car.id)}
                        className="iconlocation"
                        alt=""
                        src="../iconclose.svg"
                      />
                    </div>
                  </div>
                ) : (
                  <div key={index} id="listrow-div2" className="listrow-div2">
                    <div
                      className="savebutton-div"
                      onClick={() => favoriteCar(car.id)}
                    >
                      <img
                        className="iconlocation"
                        alt=""
                        src={
                          favoriteListData.indexOf(car.id) !== -1
                            ? "../iconheartActive_1.svg"
                            : "../iconheart.svg"
                        }
                      />
                    </div>
                    {car.sold === false ? (
                      <img
                        className="image-icon"
                        alt=""
                        src={car.image}
                        onMouseOver={() => imageDetail(true)}
                      // onClick={() => carDetail(car.id)}
                      />
                    ) : (
                      <div className="sold-container">
                        <img
                          className="image-icon-sold"
                          alt=""
                          src={car.image}
                          onClick={() => carDetail(car.id)}
                        />
                        <h1 className="sold-txt">Sold</h1>
                      </div>
                    )}
                    <div id="content-div5" className="content-div5" onClick={() => carDetail(car.id)}>
                      <div className="price-div">
                        {car.year} {car.make} {car.model}
                      </div>
                      <div className="richmond-va-64-miles-away" onClick={() => carDetail(car.id)}>
                        {car.location} ({car.distance})
                      </div>
                    </div>
                    <div className="input-value-div" onClick={() => carDetail(car.id)} >{car.miles} miles</div>
                    <div className="input-value-div" onClick={() => carDetail(car.id)}>
                      {car.priceunit}{car.price}
                    </div>
                    <div className="savebutton-div">
                      <img
                        onClick={() => remove(car.id)}
                        className="iconlocation"
                        alt=""
                        src="../iconclose.svg"
                      />
                    </div>
                  </div>
                )}
              </Fragment>
            ) : null
          )}
      </div> */}
      
      </Fragment>
  );
};

export default CarList;
