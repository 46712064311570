export enum InteriorColorEnum {
    N_A="",
    Beige="#CFC8BE",
    Black="#1A1A1A",
    Blue="#2A4679",
    Brown="#513D3C",
    Gray="#838288",
    Green="#0E502C",
    Orange="#CD5A31",
    Red="#AE081F",
    White="#FFFFFF",
    Yellow="#F0C834",
    Unknown="",
  }

  export function getInteriorColorEnum(){
    return Object.keys(InteriorColorEnum) as Array<keyof typeof InteriorColorEnum>;
  }