import "./FilterItems.css";
import {
    Box,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Checkbox,
} from "@chakra-ui/react";

const ONlineDealers = (props: any) => {
    const { setONlineDealersFilter } = props;
    function onChange(e: any) {
        setONlineDealersFilter(e.target.checked)
    }
    return (
        <AccordionItem className="filter-div-acordion-item">
            <h2>
                <AccordionButton className="acordion-button">
                    <Box as="span" flex='1' textAlign='left' height={10}>
                    Online Dealers
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} className="acordion-panel">
                <Checkbox onChange={(e)=>onChange(e)} colorScheme='red' size='lg'>Show online dealers</Checkbox>
                <p className="accordion-item-p">
                    These are dealerships that do not have a physical location, meaning that cars will be delivered to your home.
                </p>
            </AccordionPanel>
        </AccordionItem>
    );
};

export default ONlineDealers;
