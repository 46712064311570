export enum FuelTypeEnum {
    CNG="CNG",
    Diesel="Diesel",
    Electric="Electric",
    Gas="Gas",
    Hybrid="Hybrid",
    Hydrogen="Hydrogen",
    Plug_In_Hybrid="Plug-In-Hybrid",
  }

  export function getFuelTypeEnum(){
    return Object.keys(FuelTypeEnum) as Array<keyof typeof FuelTypeEnum>;
  }