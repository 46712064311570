export enum CarColors {
    Beige="#CFC8BE",
    Black="#1A1A1A",
    Blue="#2A4679",
    Brown="#513D3C",
    Copper="#B87333",
    Gold="#C6A655",
    Gray="#838288",
    Green="#0E502C",
    Maroon="#560D22",
    Orange="#CD5A31",
    Purple="#411E5A",
    Red="#AE081F",
    Silver="#D1D1D4",
    Tan="#E8D8BE",
    Teal="#245566",
    Undetermined="",
    White="#FFFFFF",
    Yellow="#F0C834",
    Unknown="",
  }

  export function getCarColors(){
    return Object.keys(CarColors) as Array<keyof typeof CarColors>;
  }