import "./FilterItems.css";
import {
    Box,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Checkbox,
    RadioGroup,
    Stack,
    Radio,
    CheckboxGroup,
} from "@chakra-ui/react";

const ConditionandHistory = (props: any) => {
    const { setConditionandHistoryFilter } = props;
    function onChange(e: any) {
        setConditionandHistoryFilter(e.target.checked)
    }
    return (
        <AccordionItem className="filter-div-acordion-item">
            <h2>
                <AccordionButton className="acordion-button">
                    <Box as="span" flex='1' textAlign='left' height={10}>
                        Condition and History
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4} className="acordion-panel">
                <p className="accordion-item-p-bold">
                    Number of Accidents
                </p>
                <RadioGroup defaultValue='3'>
                    <Stack spacing={5}>
                        <Radio colorScheme='red' value='1' size='md'>
                            No accidents
                        </Radio>
                        <Radio colorScheme='red' value='2' size='md'>
                            1 or fewer
                        </Radio>
                        <Radio colorScheme='red' value='3' size='md'>
                            Any number
                        </Radio>
                    </Stack>
                </RadioGroup>
                <p className="accordion-item-p-bold">
                    Title
                </p>
                <CheckboxGroup>
                    <Checkbox onChange={(e) => onChange(e)} colorScheme='red' size='lg'>Salvage</Checkbox>
                </CheckboxGroup>
                <p className="accordion-item-p">
                    Significant damage or totaled
                </p>
                <CheckboxGroup>
                    <Checkbox className="checkbox-top" onChange={(e) => onChange(e)} colorScheme='red' size='lg'>Lemon</Checkbox>
                </CheckboxGroup>
                <p className="accordion-item-p">
                    A condition where the automaker buys back the car due to warranty defects
                </p>
                <CheckboxGroup>
                    <Checkbox className="checkbox-top" onChange={(e) => onChange(e)} colorScheme='red' size='lg'>Theft Recovery</Checkbox>
                </CheckboxGroup>
                <p className="accordion-item-p">
                    Any vehicle that has been stolen from its owner and then found
                </p>
                <CheckboxGroup>
                    <Checkbox className="checkbox-top" onChange={(e) => onChange(e)} colorScheme='red' size='lg'>Frame Damage</Checkbox>
                </CheckboxGroup>
                <p className="accordion-item-p">
                    Damage to a component of the main structure of the vehicle
                </p>

                <p className="accordion-item-p-bold">
                    Number of Owners
                </p>
                <RadioGroup defaultValue='3'>
                    <Stack spacing={5}>
                        <Radio colorScheme='red' value='1' size='md'>
                            One owner
                        </Radio>
                        <Radio colorScheme='red' value='2' size='md'>
                            2 or fewer
                        </Radio>
                        <Radio colorScheme='red' value='3' size='md'>
                            Any number
                        </Radio>
                    </Stack>
                </RadioGroup>

                <p className="accordion-item-p-bold">
                    Fleet & Rental Indicator
                </p>
                <CheckboxGroup>
                    <Checkbox className="checkbox-top" onChange={(e) => onChange(e)} colorScheme='red' size='lg'>Fleet Vehicles</Checkbox>
                </CheckboxGroup>
                <p className="accordion-item-p">
                    Vehicles owned or leased by a business rather than an individual
                </p>

                <CheckboxGroup>
                    <Checkbox className="checkbox-top" onChange={(e) => onChange(e)} colorScheme='red' size='lg'>Rental Vehicles</Checkbox>
                </CheckboxGroup>
                <p className="accordion-item-p">
                    Vehicles used in a rental capacity
                </p>
            </AccordionPanel>
        </AccordionItem>
    );
};

export default ConditionandHistory;
