
import { Tag } from "@chakra-ui/react";
import { Fragment } from "react";
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, Cell, ResponsiveContainer } from "recharts";
import "./CarGraph.css";

const data = [
  // decrease
  { x: 15000, y: 60, z: 1 },
  { x: 90000, y: 15, z: 1 },
  { x: 70000, y: 30, z: 1 },
  { x: 40000, y: 58, z: 1 },
  { x: 50000, y: 52, z: 1 },
  { x: 60000, y: 40, z: 1 },
  { x: 62000, y: 44, z: 1 },
  { x: 58000, y: 45, z: 1 },
  // nominal
  { x: 95000, y: 35, z: 2 },
  { x: 115000, y: 25, z: 2 },
  { x: 90000, y: 40, z: 2 },
  { x: 85000, y: 40, z: 2 },
  { x: 88000, y: 40, z: 2 },
  { x: 55000, y: 60, z: 2 },
  { x: 65000, y: 55, z: 2 },
  { x: 30000, y: 70, z: 2 },
  //increase
  // nominal
  { x: 110000, y: 35, z: 3 },
  { x: 90000, y: 50, z: 3 },
  { x: 90000, y: 55, z: 3 },
  { x: 100000, y: 40, z: 3 },
  { x: 100000, y: 45, z: 3 },
  { x: 105000, y: 35, z: 3 },
  { x: 45000, y: 75, z: 3 },
  { x: 30000, y: 90, z: 3 }
];
const data2 = [
  { x: 5000, y: 95 },
  { x: 150000, y: 5 }
];

const CustomTooltip = () => {
  
  return (

            <div className="cat-absolute-container">

              <div className="tooltipCar">
                <img className="imageTooltip" src="../image27@2x.png"/>
                <div className="flex-container-9">
                  <div className="flex-container-10">
                    <span aria-label="2019 Nissan Sentra SV">2019 Nissan Sentra SV</span>
                    <span className="icon-buttonTooltip" >x</span> 
                  </div>
                  <div className="flex-container-11">
                    <img className="iconspeedometer"    src="../iconspeedometerBlack.svg" />
                    <span className="milesValue" aria-label="45,797 miles">45,797 miles</span>
                    <Tag className="tag-instance-3" aria-label="Overpriced">Overpriced</Tag>
                  </div>
                  <div className="flex-container-12">
                    <img className="iconmoney"  src="../iconmoneyBlack.svg" />
                    <span className="moneyValue" >$19,198</span>
                    
                  </div>
                  <div className="flex-container-13">
                  <span className="learn-more" aria-label="Learn more">Learn more »</span>
                  </div>
                
                </div>
              </div>
            </div>

  );



};
const CarGraph = () => {
  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <ScatterChart className="carGraph"


        >
          <XAxis type="number" dataKey="x" name="stature" unit="" label={"adfsd"} />
          <YAxis type="number" dataKey="y" name="weight" unit="k $" />
          <Tooltip  content={CustomTooltip} />
          <Scatter name="carDara" data={data}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  entry.z === 1 ? "#00C49F" : entry.z === 2 ? "#F9E796" : "#F890A2"
                }
              />
            ))}
          </Scatter>
          <Scatter name="verticelLine" data={data2} fill="gray" line strokeWidth={3} shape="wye" />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CarGraph;
