import { Fragment } from "react";

const NoExactMathes = (props: any) => {
  const { clearFilterData, clearFilter, switchBar } = props;
  const clearFilterFunc = () => {
    clearFilter(clearFilterData + 1);
  };
  return (
    <Fragment>
      <div className="title-div"> {switchBar === 3 ? "No saved cars" : "No Exact matches"}</div>
      {switchBar === 3 ? null : <Fragment>
        <div aria-label="Try changing or removing some of your filters or adjusting your location.">
          Try changing or removing some of your filters or adjusting your
          location.
        </div>
        <div id="clear-tools" className="Clear-Tools">
          <div
            id="clearButton"
            className="clearButton"
            style={{ marginTop: 10 }}
            onClick={() => clearFilterFunc()}
          >
            <button className="label-div" aria-label="Clear filters">Clear filters</button>
          </div>
        </div></Fragment>}

    </Fragment>
  );
};

export default NoExactMathes;
