
export enum SortTypes {
    Best_Macth="Best Match",
    // Best_Detail="Best Detail",
    Price_Low_to_High="Price Low to High",
    Price_High_to_Low="Price High to Low",
    //Distance="Distance",
    Mileage_Low_to_High="Mileage Low to High",
    Mileage_High_to_Low="Mileage High to Low",
    Year_Low_to_High="Year Low to High",
    Year_High_to_Low="Year High to Low",
    //Newly_listed="Newly listed"
  }

  export function getSortTypes(){
    return Object.keys(SortTypes) as Array<keyof typeof SortTypes>;
  }
