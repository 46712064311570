import MakeData from "../MakeData";
import ModelData from "../ModelData";


export const makeList: MakeData[] = [
    { Id: 1, Name: "Nissan" },
    { Id: 2, Name: "Hyundai" },
    { Id: 3, Name: "Ford" },
    { Id: 4, Name: "Toyota" },
    { Id: 5, Name: "Jeep" },
    { Id: 6, Name: "Chrysler" },
    { Id: 7, Name: "Land Rover" },
    { Id: 8, Name: "GMC" },
    { Id: 9, Name: "Chevrolet" },
    { Id: 10, Name: "Lexus" },
    { Id: 11, Name: "Ram" },
    { Id: 12, Name: "Mercedes-Benz" },
    { Id: 13, Name: "Honda" },
  ];
export const ModelList: ModelData[]=[

  { Id: 1, MakeId: "Nissan", Name: "Sentra" },
  { Id: 2, MakeId: "Nissan", Name: "Altima" },
  { Id: 3, MakeId: "Nissan", Name: "Rogue" },
  { Id: 4, MakeId: "Hyundai", Name: "Sonata" },
  { Id: 5, MakeId: "Hyundai", Name: "Elantra" },
  { Id: 6, MakeId: "Ford", Name: "Camry" },
  { Id: 7, MakeId: "Ford", Name: "RAV4" },
  { Id: 8, MakeId: "Ford", Name: "Sienna" },
  { Id: 9, MakeId: "Jeep", Name: "Compass" },
  { Id: 10, MakeId: "Chrysler", Name: "Voyager" },
  { Id: 11, MakeId: "Range Rover", Name: "Range Rover" },
  { Id: 12, MakeId: "GMC", Name: "Acadia" },
  { Id: 13, MakeId: "GMC", Name: "Terrain Denali" },
  { Id: 14, MakeId: "Chevrolet", Name: "Equinox" },
  { Id: 15, MakeId: "Chevrolet", Name: "Silverado 1500" },
  { Id: 16, MakeId: "Lexus", Name: "Sienna" },
  { Id: 17, MakeId: "Ram", Name: "1500" },
  { Id: 18, MakeId: "Mercedes-Benz", Name: "GL" },
  { Id: 19, MakeId: "Mercedes-Benz", Name: "C-Class" },
  { Id: 21, MakeId: "Mercedes-Benz", Name: "CLS" },
  { Id: 21, MakeId: "Honda", Name: "Passport" },

  ];

  