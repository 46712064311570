import { useState, useEffect, Fragment, useLayoutEffect, } from "react";
import "./SearchList.css";
import Cookies from 'universal-cookie';
import CarListModel from "../../Model/CarListModel";
import { AccordionPanel, Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, useDisclosure, useStatStyles } from "@chakra-ui/react";
import { Pagination } from "react-pagination-bar";
import "react-pagination-bar/dist/index.css";
import { getSortTypes, SortTypes } from "../pages/Filters/Enums/SortingTypes";
import NoExactMathes from "../pages/NoMatches/NoExactMathes";
import CarGrid from "../pages/CarGrid/CarGrid";
import CarList from "../pages/CarList/CarList";
import Filters from "../pages/Filters/Filters";
import data from './data.json';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
} from '@chakra-ui/react'
import { useParams } from "react-router-dom";
import CarBestDeal from "../pages/CarBestDeal/CarBestDeal";
import { ChevronDownIcon } from "@chakra-ui/icons";
import FilterLocation from "../pages/FilterLocation/FilterLocation";
import FilterModel from "../../Model/FilterModel";
import { distance } from "framer-motion";


const SearchList = () => {
  const [carList, setCarList] = useState<CarListModel[]>([]);
  const [carListTemp, setCarListTemps] = useState<CarListModel[]>([]);
  const [filterCount, setFilterCount] = useState(false);
  const [isError, setIsError] = useState(true);
  const [isFilter, setIsFilter] = useState(false);//sayfada filtreli bi öğe varmı ?
  //----------Filtreleme Elemanları------------//
  const [makeVal, setMakeVal] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [zipCodeSuccess, setZipCodeSuccess] = useState("All");
  const [saveLocationProp, setSaveLocation] = useState(false);
  const [Distance, setDistance] = useState("50");
  const [modelVal, setModelVal] = useState("");
  const [minYearVal, setMinYearVal] = useState(0);
  const [maxYearVal, setMaxYearVal] = useState(0);
  const [minPrice, setMinPriceVal] = useState("0");
  const [maxPrice, setMaxPriceVal] = useState("100,000+");
  const [minMileage, setMinMileageVal] = useState("0");
  const [maxMileage, setMaxMileageVal] = useState("200,000+");
  const [minPriceFilter, setMinPriceFilterVal] = useState("0");
  const [maxPriceFilter, setMaxPriceFilterVal] = useState("100,000+");
  const [minMileageFilter, setMinMileageFilterVal] = useState("0");
  const [maxMileageFilter, setMaxMileageFilterVal] = useState("200,000+");

  //----------Filtreleme Elemanları------------//
  const filterData: FilterModel = {
    makeVal: makeVal,
    setMakeVal: setMakeVal,
    zipCode: zipCode,
    setZipCode: setZipCode,
    zipCodeSuccess: zipCodeSuccess,
    setZipCodeSuccess: setZipCodeSuccess,
    saveLocationProp: saveLocationProp,
    setSaveLocation: setSaveLocation,
    Distance: Distance,
    setDistance: setDistance,
    modelVal: modelVal,
    setModelVal: setModelVal,
    minYearVal: minYearVal,
    setMinYearVal: setMinYearVal,
    maxYearVal: maxYearVal,
    setMaxYearVal: setMaxYearVal,
    minPrice: minPrice,
    setMinPriceVal: setMinPriceVal,
    maxPrice: maxPrice,
    setMaxPriceVal: setMaxPriceVal,
    minMileage: minMileage,
    setMinMileageVal: setMinMileageVal,
    maxMileage: maxMileage,
    setMaxMileageVal: setMaxMileageVal,
    minPriceFilter: minPriceFilter,
    setMinPriceFilterVal: setMinPriceFilterVal,
    maxPriceFilter: maxPriceFilter,
    setMaxPriceFilterVal: setMaxPriceFilterVal,
    minMileageFilter: minMileageFilter,
    setMinMileageFilterVal: setMinMileageFilterVal,
    maxMileageFilter: maxMileageFilter,
    setMaxMileageFilterVal: setMaxMileageFilterVal,
  };
  const [FilterModelData, setFilterModelData] = useState<FilterModel>(filterData);
  useEffect(() => {
    setFilterModelData(filterData);
  }, [makeVal, zipCode, zipCodeSuccess, saveLocationProp, Distance, modelVal, minYearVal, maxYearVal, minPrice, maxPrice, minMileage, maxMileage, minPriceFilter, maxPriceFilter, minMileageFilter, maxMileageFilter]);

  useEffect(() => {
    getDataList();
  }, []);
  const getDataList = () => {
    // axios(data).then((response: AxiosResponse) => {

    setCarListTemps(data);

    if (JSON.parse(params)?.switchBar == "Saved") {
      setSwitchBar(3);
      if (cookies.get('FavoriteList')) {

        setCarList(data.filter(x => cookies.get('FavoriteList').includes(x.id)));
      }
    }
    else {
      setCarList(data);
    }
    setFilterController(true)

    // });
  };
  const cookies = new Cookies();
  const params = JSON.stringify(useParams());


  const [switchBar, setSwitchBar] = useState(params && JSON.parse(params)?.switchBar && JSON.parse(params)?.switchBar === "Saved" ? 3 : 1);
  const [favoriteListData, setFavoriteListData] = useState<number[]>([]);
  const [favoriteList, setFavoriList] = useState(undefined);
  const [removedListData, setRemovedListData] = useState<number[]>([]);
  const [RemoveList, setRemoveList] = useState(undefined);


  const [screenWidth, setScreenWidth] = useState(window.screen.availWidth);

  useEffect(() => {
    if (favoriteList) {
      setFavoriteListData(favoriteList);
    }
  }, [favoriteList]);

  useEffect(() => {
    setPage(1);
  }, [switchBar]);
  useEffect(() => {
    if (RemoveList) {
      setRemovedListData(RemoveList);
    }
  }, [RemoveList]);

  useEffect(() => {
    setFavoriList(cookies.get('FavoriteList'))
    setRemoveList(cookies.get('RemovedList'))
  }, []);

  const onClickFilterClear = () => {
    // setSwitchBar(params && JSON.parse(params)?.switchBar && JSON.parse(params)?.switchBar === "Saved" ? 3 : 1);
    // setLocationName("All")
    // setSorting(SortTypes.Best_Macth.toString());
    setFilterClear(filterClear + 1)

  }

  const [locationName, setLocationName] = useState("All");
  const [sorting, setSorting] = useState(SortTypes.Best_Macth.toString());
  const [filterClear, setFilterClear] = useState(0);
  const [filterController, setFilterController] = useState(false);

  const [page, setPage] = useState(1);
  const [keyControl, setKeyControl] = useState(1);
  const [listType, setListType] = useState(cookies.get('ListType') ? parseInt(cookies.get('ListType')) : 2);
  const [FilterRefresh, setFilterRefresh] = useState(1);

  useEffect(() => {
    if (FilterRefresh > 1) {
      setKeyControl(keyControl + 1);
    }
    setFilterRefresh(FilterRefresh + 1);
    setMakeVal("");
    setZipCode("");
    setZipCodeSuccess("All");
    setSaveLocation(false);
    setDistance("50");
    setModelVal("");
    setMinPriceVal("0");
    setMaxPriceVal("100,000+");
    setMinMileageVal("0");
    setMaxMileageVal("200,000+");
    setMinPriceFilterVal("0");
    setMaxPriceFilterVal("100,000+");
    setMinMileageFilterVal("0");
    setMaxMileageFilterVal("200,000+");
    setZipCodeSuccess("All");
    setLocationName("All");
    setMinYearVal(0);
    setMaxYearVal(0);

  }, [filterClear]);

  const handleChangePage = (value: number) => {
    setPage(value);
    window.scrollTo(0, 0);

  };
  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateSize);
    updateSize();

  }, []);//Bu Fonskiyon Responsive Tetiklemek için


  const favoriteCar = (value: number) => {

    let favoriteList = favoriteListData;
    if (favoriteListData.indexOf(value) === -1) {
      favoriteList.push(value)
    }
    else {
      favoriteList.splice(favoriteListData.indexOf(value), 1);
    }

    setFavoriteListData(favoriteList)
    cookies.set('FavoriteList', JSON.stringify(favoriteList));
    setKeyControl(keyControl + 1);
  };


  const removeCar = (value: number) => {

    let remove = removedListData;
    if (removedListData.indexOf(value) === -1) {
      remove.push(value)
    }
    else {
      remove.splice(removedListData.indexOf(value), 1);
    }

    setRemovedListData(remove)
    cookies.set('RemovedList', JSON.stringify(remove));
    setKeyControl(keyControl + 1);
  };
  const showHidden = () => {
    cookies.remove("RemovedList")
    setRemovedListData([])
  }


  const [isOpen, setOpen] = useState(false);

  const filterDivClick = () => {
    setOpen(true)
  }

  const onClickFilterSave = () => {
    setOpen(false)
  }

  const [isOpenLocation, setOpenLocation] = useState(false);

  const filterDivClickLocation = () => {
    setDistance(Distance);
    setZipCode(zipCode);
    setOpenLocation(true)
  }

  const onClickFilterSaveLocation = () => {
    setLocationName(zipCode);
    setZipCode(zipCode);
    setZipCodeSuccess(zipCode);
    setDistance(Distance);
    setOpenLocation(false)
  }


  return (

    <Fragment>
      <div className="content-div1">

        <Modal onClose={() => setOpenLocation(false)} size={"full"} isOpen={isOpenLocation}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Location</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FilterLocation

                zipCode={zipCode}
                setZipCode={setZipCode}
                Distance={Distance}
                setDistance={setDistance} />
            </ModalBody>
            <ModalFooter>
              <Button className="filterClearButton" onClick={() => setOpenLocation(false)}>Cancel</Button>
              <Button className="filterSaveButton" onClick={() => onClickFilterSaveLocation()}>Save</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal onClose={() => setOpen(false)} size={"full"} isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>More Filters</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Filters
                FilterModel={FilterModelData}
                setPageNumber={setPage}
                screenWidth={screenWidth}
                setFilterClear={setFilterClear}

                setKeyControl={setKeyControl}
                setFilterController={setFilterController}
                setSwitchBar={setSwitchBar}
                switchBar={switchBar}
                key={FilterRefresh}
                setCarListTemps={setCarListTemps}
                setCarLists={setCarList}
                carList={carList}
                carListTemp={carListTemp}
                sort={sorting}
                setLocationName={setLocationName}
                filterClear={filterClear}
                keyControl={keyControl}
              ></Filters>
            </ModalBody>
            <ModalFooter>
              <Button className="filterClearButton" onClick={() => onClickFilterClear()}>Clear</Button>
              <Button className="filterSaveButton" onClick={() => onClickFilterSave()}>Save</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>


        <div className="mobileFiltersDiv">

          <div className="location-responsive" onClick={() => filterDivClickLocation()}>
            <img className="iconlocation" alt="" src="../iconlocation.svg" /> <span className="location-responsive-text">{zipCode != "" ? zipCode : "Location"}</span>
          </div>
          <div className="filterDiv-responsive" onClick={() => filterDivClick()}>
            More Filters <ChevronDownIcon boxSize={8} />
          </div>
        </div>
        <div id="filterdivdesk" className={"filter-rescponsive-div-desk"}>

          {/* <div id="filterdivdesk" className={filterDivDisplayisNone ? "filter-rescponsive-div-desk " :"filter-rescponsive-div-desk displayBlock"}> */}
          <Filters
            FilterModel={FilterModelData}
            setPageNumber={setPage}
            screenWidth={screenWidth}
            setFilterClear={setFilterClear}

            setKeyControl={setKeyControl}
            setFilterController={setFilterController}
            setSwitchBar={setSwitchBar}
            switchBar={switchBar}
            key={FilterRefresh}
            setCarListTemps={setCarListTemps}
            setCarLists={setCarList}
            carList={carList}
            carListTemp={carListTemp}
            sort={sorting}
            setLocationName={setLocationName}
            filterClear={filterClear}
            keyControl={keyControl}
          ></Filters>
        </div>
        <div id="results-div" className="results-div">
          {carList.filter(f => !removedListData.includes(f.id)).length > 0 ? <Box w="100%" p={4} className="sort-box">
            <div className="results-div1">{carList.filter(f => !removedListData.includes(f.id)).length} results</div>
            <div className="sort-div">
              <div className="sort-div1">
                <label aria-label="Sort By">Sort By</label>
              </div>
              <Select

                id="sortTypeSelect"
                variant="unstyled"
                width={screenWidth < 1000 ? 190 : 250}
                maxWidth={screenWidth < 1000 ? 190 : 250}
                minWidth={screenWidth < 1000 ? 190 : 250}
                className="select-div-sort"
                value={sorting}
                onChange={(e) => {
                  setSorting(e.target.value);
                }}
              >
                {getSortTypes().map((key, index) => (
                  <option key={index} value={SortTypes[key]}>
                    {SortTypes[key]}
                  </option>
                ))}
              </Select>
            </div>
          </Box> : null}
          <Box w="100%" p={1} key={keyControl} />
          {carList.length > 0 ? (

            <div>
              {" "}
              <div className="list-div3">
                {listType === 2 ? (
                  <>
                    {/* <div className="header-div">
                      <div className="save-div">Save</div>
                      <div className="photo-div">Photo</div>
                      <div className="year-make-model">Year Make Model</div>
                      <div className="miles-div">Miles</div>
                      <div className="price-div1">Price</div>
                      <div className="remove-div">Remove</div>
                    </div> */}
                    <CarList page={page} carListModel={carList} favoriteListData={favoriteListData} setFavoriteId={(id: number) => favoriteCar(id)}
                      removedListData={removedListData} setRemovedId={(id: number) => removeCar(id)}></CarList>
                  </>
                ) : listType === 3 ? (
                  <CarGrid screenWidth={screenWidth} page={page} carList={carList} favoriteListData={favoriteListData} setFavoriteId={(id: number) => favoriteCar(id)}
                    removedListData={removedListData} setRemovedId={(id: number) => removeCar(id)}></CarGrid>
                ) : (
                  <CarBestDeal screenWidth={screenWidth} page={page} carList={carList} favoriteListData={favoriteListData} setFavoriteId={(id: number) => favoriteCar(id)}
                    removedListData={removedListData} setRemovedId={(id: number) => removeCar(id)}></CarBestDeal>
                )}
              </div>
              <div className="tools-div1">
                <div className="pagination-div">
                  <div className="iconbutton-div">


                    <Pagination

                      currentPage={page}
                      itemsPerPage={15}
                      onPageChange={(pageNumber) =>
                        handleChangePage(pageNumber)
                      }
                      totalItems={carList.filter(f => !removedListData.includes(f.id)).length}
                      pageNeighbours={4}
                    />
                  </div>
                </div>
                <div className="button-div5" style={{ marginTop: 10 }} onClick={() => showHidden()}>
                  <img className="icon4" alt="" src="../icon4.svg" />
                  <div className="label-div">
                    <p aria-label="Show Hidden">Show Hidden</p>
                  </div>
                </div>

              </div>
            </div>
          ) : (


            filterController ? (
              <NoExactMathes
                switchBar={switchBar}
                clearFilter={setFilterClear}
                clearFilterData={filterClear}
              />
            ) : null
          )
          }
        </div>
      </div>
      <div id="hero-div" className="hero-div">
        <div id="content-div36" className="content-div36">
          <div id="breadcrumbs-div" className="breadcrumbs-div">
            <div id="cars-best-minvans" className="cars-best-minvans">
              Car Listings / {locationName}
            </div>
          </div>
          <b className="used-car-listings-in-richmond">
            Used Car Listings in {locationName}
          </b>
        </div>
        <div className="radioboxrow-div">
          <div
            className={listType === 1 ? "radiobox-div radiobox-div-selected" : "radiobox-div"}
            onClick={() => { cookies.set('ListType', 1); setListType(1) }}
          >
            <img className={"accordionicon"} alt="heart icon" src={listType === 1 ? "../iconchartblue.svg" : "../iconchart.svg"} />
            <div className="best-deal-div"><p aria-label="Best Deal">Best Deal</p></div>
          </div>
          <div
            className={listType === 2 ? "radiobox-div radiobox-div-selected" : "radiobox-div"}
            onClick={() => { cookies.set('ListType', 2); setListType(2) }}
          >
            <img className={"accordionicon"} alt="acordion icon" src={listType === 2 ? "../iconlistblue.svg" : "../iconlist.svg"} />
            <div className="best-deal-div">List</div>
          </div>
          <div
            className={listType === 3 ? "radiobox-div radiobox-div-selected" : "radiobox-div"}
            onClick={() => { cookies.set('ListType', 3); setListType(3) }}
          >
            <img className={"accordionicon"} alt="acordion icon" src={listType === 3 ? "../icongridblue.svg" : "../icongrid.svg"} />
            <div className="best-deal-div">Grid</div>
          </div>
        </div>
      </div>
    </Fragment>

  );
};

export default SearchList;
