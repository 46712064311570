export enum PopulerFeaturesEnum {
    Adaptive_Cruise_Control="Adaptive Cruise Control",
    Backup_Camera="Backup Camera",
    Blind_Spot_System="Blind Spot System",
    Bluetooth="Bluetooth",
    Front_Cooled_Seats="Front Cooled Seats",
    Front_Heated_Seats="Front Heated Seats",
    Lane_Keep_Assist="Lane Keep Assist",
    Moonroof="Moonroof",
    Multi_Zone_Climate_Control="Multi-Zone Climate Control",
    Navigation="Navigation",
    Parking_Sensors="Parking Sensors",
    Power_Trunk_Lift_gate="Power Trunk/Liftgate",
    Premium_Audio="Premium Audio",
    Premium_Seat_Material="Premium Seat Material",
    Premium_Wheels="Premium Wheels",
    Remote_Engine_Start="Remote Engine Start",
    Third_Row_Seating="Third Row Seating",
    Tow_Hitch="Tow Hitch",
    Unknown="Unknown",
  }

  export function getPopulerFeaturesEnum(){
    return Object.keys(PopulerFeaturesEnum) as Array<keyof typeof PopulerFeaturesEnum>;
  }