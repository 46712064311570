export enum DriveTypeEnum {
    FourWD="4WD",
    AWD="AWD",
    FWD="FWD",
    RWD="RWD",
    Unknown="Unknown",
  }

  export function getDriveTypeEnum(){
    return Object.keys(DriveTypeEnum) as Array<keyof typeof DriveTypeEnum>;
  }